import axios from "axios";
import {cleanHeaders} from "../clean-headers";

const login = async (params) => {
  const headers = cleanHeaders(params);

  try {
    const response = await axios.post(
      "https://music.videomite.tech/api/Account/login",
      null,
      { headers }
    );

    return response.data;
  } catch (error) {
    console.log("Request Failed", error);
    return error.response;
  }
};

export default login;
