import axios from "axios";
import {formatDate} from "../format-date";
import {cleanHeaders} from "../clean-headers";

const getProducts = async (params) => {
  const headers = cleanHeaders(params);

  try {
    const response = await axios.get(
      "https://music.videomite.tech/api/product",
      { headers }
    ).then((res) => res.data);

    return response.map((product) => ({
      ...product,
      createdTime: formatDate(product.createdTime),
    }));
  } catch (error) {
    console.log(error);
  }
};

export { getProducts };
