import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenModal } from "../../stores/modal";
import { animated } from "react-spring";
import { toast, ToastContainer } from "react-toastify";
import { CircleStackIcon } from "@heroicons/react/20/solid";
import PlakIcon from "../../assets/icons/PlakIcon";
import Loading from "../Loading";

export default function CreateProduct() {
  const { openModal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { credential, partnerIds, isAdmin } = useSelector(
    (state) => state.user
  );
  const [createProduct, setCreateProduct] = useState("");
  const [createPartner, setCreatePartner] = useState("");
  const closeModal = () => {
    dispatch(setOpenModal(false));
  };
  const [selectedFile, setSelectedFile] = useState(null);

  const [preview, setPreview] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview('');
    }
  };

  const handleInputClick = () => {
    setIsFocused(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCreateProduct = async (event) => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${credential}`,
      partnerId: createPartner,
    };
    const formData = new FormData();
    formData.append('coverArtFile', selectedFile);
    formData.append('title', createProduct);

    try {
      const response = await fetch(`https://music.videomite.tech/api/product/createWithCoverArt`, {
        method: 'POST',
        body: formData,
        headers
      });
      const responseData = await response.json();
      dispatch(setOpenModal(false));
      setLoading(false);
      window.location.href = `/catalog/products/${responseData.id}`;
    } catch (error) {
      toast.error("API isteği sırasında bir hata oluştu:", error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ToastContainer autoClose={3000} />
      <Transition.Root show={openModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                  <div>
                    <div className="text-lg font-normal px-4 pt-5">
                      <h1>Create New Product</h1>
                    </div>
                    <div className="mt-3 sm:mt-5 flex flex-col items-center gap-3 px-4 pb-4">
                      <div className="flex items-center justify-center w-44 relative">
                        <label
                          htmlFor="coverArtFile"
                          className="flex flex-col items-center justify-center w-44 h-44 rounded-primary cursor-pointer bg-[#e8e8e8] hover:bg-black hover:bg-opacity-50"
                        >
                          <div className="flex flex-col items-center justify-center py-4 hover:before:content-['update']  hover:before:bottom-0">
                            {selectedFile?.type?.includes('image') ? (
                                <img
                                    className='w-52 h-52 object-cover shadow-inner'
                                    src={preview} alt="" />
                            ) : (
                                <PlakIcon className="w-52 h-52 p-2" />
                            )}
                          </div>
                          <input
                            id="coverArtFile"
                            name="coverArtFile"
                            type="file"
                            className="hidden"
                            onChange={handleFileChange}
                          />
                        </label>
                      </div>
                      <div className="relative w-full">
                        <label
                          className={
                            isFocused || createProduct
                              ? "bg-white px-1 font-normal text-gray-400"
                              : " inline-block bg-white px-1 font-normal text-gray-400"
                          }
                        >
                          Product name*
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          onClick={handleInputClick}
                          ref={inputRef}
                          value={createProduct}
                          onChange={(e) => setCreateProduct(e.target.value)}
                          className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2"
                        />
                      </div>
                      {isAdmin && (
                        <div className="relative w-full">
                          <animated.label className="absolute top-0 left-0 inline-block bg-white px-1 font-normal text-gray-400">
                            Select Partner
                          </animated.label>
                          <div className="relative">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <CircleStackIcon
                                className="h-4 w-4 text-gray-300"
                                aria-hidden="true"
                              />
                            </div>

                            <select
                              className="block w-full rounded-md bg-white py-2 pl-7 pr-10 border border-gray-300 text-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm"
                              onChange={(e) => setCreatePartner(e.target.value)}
                            >
                              <option value="">Select Partner</option>
                              {partnerIds?.map((partner) => (
                                <option key={partner.id} value={partner.id}>
                                  {partner.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                    <div></div>
                    <div className="flex items-center gap-4">
                      <button
                        type="button"
                        className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md bg-primary px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 uppercase sm:col-start-2"
                        onClick={handleCreateProduct}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
