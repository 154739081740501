import axios from "axios";
import {cleanHeaders} from "../clean-headers";

const getArtistProducts = async (params, id) => {
    const headers = cleanHeaders(params);

    try {
        return await axios.get(
            `https://music.videomite.tech/api/Product/getAllByArtistId?artistId=${id}`,
            {headers}
        ).then((res) => res.data);
    } catch (error) {
        console.log(error);
    }
};

export { getArtistProducts };
