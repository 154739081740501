import { ChevronDownIcon, PencilIcon, PlusIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setOpenModal } from "../../stores/modal";
import { setPeopleIdentifierData } from "../../stores/peopleIdentifierData";
import { Dialog, Transition } from '@headlessui/react';
import Loading from "../Loading";
import { toast, ToastContainer } from "react-toastify";
import {useCookies} from "react-cookie";


export default function PersonIdentifier() {
    const { id } = useParams();
    const { credential } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [addIdentifier, setAddIdentifier] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deletingIdentifierId, setDeletingIdentifierId] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedIdentifier, setSelectedIdentifier] = useState();
    const peopleIdentifierData = useSelector((state) => state.peopleIdentifierData);
    const identifiers = ['Apple Music', 'Audomack', 'Spotify', 'SoundCloud', 'Spotify DJ Mixes opt-in'];
    const [filteredIdentifiers, setFilteredIdentifiers] = useState();
    const [editIdentifier, setEditIdentifier] = useState(false);
    const [editingIdentifierValue, setEditingIdentifierValue] = useState(null);
    const [editingIdentifierId, setEditingIdentifierId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editedIdentifierName, setEditedIdentifierName] = useState();
    const [openCreateIdentifier, setOpenCreateIdentifier] = useState(false);
    const [createIdentifierValue, setCreateIdentifierValue] = useState('');
    const [cookie, setCookie] = useCookies(["selectedPartnerId"]);

    const headerSet = {
        Authorization: `Bearer ${credential}`,
        // PartnerId: getPartnerIds(partnerIds),
    };
    const handleDeleteModal = (identifierId) => {
        setDeleteModal(!deleteModal);
        setDeletingIdentifierId(identifierId);
    }

    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchQuery(value);
    };

    const handleSelectedIdentifier = (ıdentifier) => {
        setSelectedIdentifier(ıdentifier);
        setOpenCreateIdentifier(true);
        closeIdentifierModal();
    };

    const closeIdentifierModal = () => {
        setAddIdentifier(false);
        setSearchQuery('');
        setEditIdentifier(false);
    }


    const closeModal = () => {
        dispatch(setOpenModal(false));
        setEditIdentifier(false);
        setEditedIdentifierName('');
    }


    function selectPeopleNameForEditing(identifierValue, identifierId) {
        setEditingIdentifierValue(identifierValue);
        setEditingIdentifierId(identifierId);
    }

    useEffect(() => {
        if (!peopleIdentifierData) return;

        if (!Array.isArray(peopleIdentifierData)) {
            console.error('peopleIdentifierData dizi olmalı:', peopleIdentifierData);
            return;
        }

        const addedIdentifierNames = peopleIdentifierData.map(idn => idn.identifierType.toLowerCase());

        const searchFilteredIdentifiers = identifiers.filter(ide =>
            ide.toLowerCase().includes(searchQuery.toLowerCase()) &&
            !addedIdentifierNames.includes(ide.toLowerCase())
        );

        setFilteredIdentifiers(searchFilteredIdentifiers);
    }, [searchQuery, peopleIdentifierData]);

    // get ıdentifier data
    useEffect(() => {
        const fectPeopleIdentifierData = async () => {
            const headers = {
                ...headerSet,
                partnerId: cookie.selectedPartnerId
            };
            try {
                const response = await axios.get(`https://music.videomite.tech/api/person/${id}`, { headers });
                const identifiers = response.data.personIdentifiers;
                dispatch(setPeopleIdentifierData(identifiers));
                setLoading(false);

            } catch (error) {
                console.error('API isteği sırasında bir hata oluştu:', error);
                setLoading(true);
            }
        };
        fectPeopleIdentifierData();
    }, [dispatch]);


    //delete added identifier data
    const deleteAddedIdentifierData = async () => {
        const headers = {
            ...headerSet,
            "Content-Type": "application/json",
            partnerId: cookie.selectedPartnerId
        };
        try {
            const response = await axios.delete(`https://music.videomite.tech/api/person/${id}/personIdentifierRelation/${deletingIdentifierId}`, {
                headers: headers
            });
            const deleteIdentifierData = response.data;
            dispatch(setPeopleIdentifierData(deleteIdentifierData));
            setLoading(false);
            handleDeleteModal();
            window.location.reload();
        } catch (error) {
            console.error('API isteği sırasında bir hata oluştu:', error);
            setLoading(true);
        }
    };

    const handleChangeIdentifier = (e) => {
        const { value } = e.target;
        setEditedIdentifierName(value);
        setEditingIdentifierValue(value);
        setCreateIdentifierValue(value);
    }

    //edited added ıdentifier data
    const editedAddedIdentifierData = async () => {
        const headers = {
            ...headerSet,
            "Content-Type": "application/json",
            partnerId: cookie.selectedPartnerId
        };
        try {
            const response = await axios.put(`https://music.videomite.tech/api/person/${id}/personIdentifierRelation/${editingIdentifierId}`, {
                identifierValue: editingIdentifierValue
            }, {
                headers: headers
            });
            const updatedIdentifierValue = response.data;
            dispatch(setPeopleIdentifierData(updatedIdentifierValue));
            setLoading(false);
            window.location.reload();
        } catch (error) {

            toast.error(error.response.data.message);
        }
    };

      //post ıdentifier data
      const createIdentifierData = async () => {
          const headers = {
              ...headerSet,
              "Content-Type": "application/json",
              partnerId: cookie.selectedPartnerId
          };
        try {
            let identifierValue = createIdentifierValue;

            if (selectedIdentifier === 'Spotify') {
                identifierValue = `spotify:artist:${createIdentifierValue}`;
            } else if (selectedIdentifier === 'Fuga') {
                identifierValue =`fuga:artist:${createIdentifierValue}`;
            }

            const response = await axios.post(`https://music.videomite.tech/api/person/${id}/personIdentifierRelation`, {
                identifierType: selectedIdentifier,
                identifierValue
            }, {
                headers: headers
            });
            const createdIdentifier = response.data;
            dispatch(setPeopleIdentifierData(createdIdentifier))
            dispatch(setOpenModal(false));
            closeModal();
            window.location.reload();
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.errors['$.identifierType'][0]);
              }
        }

    };

    if (loading) {
        return <Loading />
    }


    return (
        <>
            <div className="mx-auto max-w-7xl mb-8">
                <ToastContainer autoClose={3000} />
                <div className='mt-8 bg-white border border-[#e2e2e2]'>
                    <h2 className='text-[#616161] text-[13px] font-semibold leading-[1.2] py-3 px-6'>Person Identifier</h2>
                    <div className='flex items-center h-[50px] py-3 p-6 text-[#212121] font-light border-b border-[#E2E2E2] bg-white text-xs'>
                        <div className='flex-[1_1_200px] mr-[10px] text-xs text-left'>
                            <span className='inline-block max-w-full overflow-hidden whitespace-nowrap uppercase'>Identifier Type</span>
                        </div>
                        <div className='flex-[1_1_200px] mr-[10px] text-xs text-left'>
                            <span className='inline-block max-w-full overflow-hidden whitespace-nowrap uppercase'>Identifier</span>
                        </div>
                        <div className='flex-[0_1_200px] mr-[10px] text-xs text-right relative'>
                            <div className='inline-block max-w-full overflow-hidden whitespace-nowrap uppercase'>
                                <div onClick={() => setAddIdentifier(true)} className='flex items-center gap-2  hover:bg-gray-100 p-2 rounded-primary cursor-pointer'>
                                    <PlusIcon className='w-5 h-5 text-primary' />
                                    <span className='text-right'>Add Identifier</span>
                                    <ChevronDownIcon className='w-5 h-5- text-primary' />
                                </div>
                                {addIdentifier &&
                                    <div className='z-10 absolute w-[300px] shadow-primary bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]'>
                                        <div className='py-2 px-4'>
                                            <div className='flex flex-col'>
                                                <div className='mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]'>
                                                    <input type='text' onChange={handleSearch} placeholder='Type here to search for identifier' className='w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary' />
                                                </div>
                                                <hr className='-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]' />
                                                <ul className='max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none'>
                                                    {filteredIdentifiers.map((identifier, index) => (
                                                        <div onClick={() => handleSelectedIdentifier(identifier)} key={index} className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${index === 0 && 'bg-gray-200'}`}>
                                                            <span className='capitalize'>{identifier}</span>
                                                        </div>
                                                    ))}
                                                    {filteredIdentifiers.length === 0 ?
                                                        <div className='h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize'>
                                                            Could not find anything
                                                        </div>
                                                        : null
                                                    }
                                                </ul>
                                                <hr className='-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]' />
                                                <div onClick={closeIdentifierModal} className='flex flex-row-reverse pt-2'>
                                                    <button className='py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100'>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    {peopleIdentifierData.length === 0 &&
                        <div className='h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center'>
                            No items available
                        </div>
                    }
                    {Array.isArray(peopleIdentifierData) && peopleIdentifierData.map((identifier, index) => {
                        return (
                            <div key={identifier.identifierType} className={index % 2 === 0 ? 'flex items-center h-[50px] py-4 p-6 text-[#212121] font-light bg-white text-xs' : 'bg-[#dc64780f] flex items-center h-[50px] py-4 p-6 text-[#212121] font-light text-xs'}>
                                <div className='flex-[1_1_200px] mr-[10px] text-xs text-left'>
                                    <span className='inline-block max-w-full overflow-hidden whitespace-nowrap'>{identifier.identifierType}</span>
                                </div>
                                <div className='flex-[1_1_200px] text-xs text-left'>
                                    {identifier.id === editingIdentifierId
                                        ? <input type='text' defaultValue={identifier.identifierValue} onChange={handleChangeIdentifier} className='w-2/3 border-b-2 py-1 outline-none bg-transparent focus:border-b-primary hover:border-b-primary' />
                                        : <span className='inline-block max-w-full overflow-hidden whitespace-nowrap'>{identifier.identifierValue}</span>
                                    }
                                </div>
                                <div className='flex-[0_1_200px] mr-[20px] text-xs text-right relative'>
                                    <div className='inline-block max-w-full overflow-hidden whitespace-nowrap uppercase'>
                                    {identifier.id === editingIdentifierId
                                            ? <div className="flex items-center gap-2">
                                                <div>
                                                    <button onClick={editedAddedIdentifierData} className="bg-transparent py-2 px-4 rounded-primary text-[14px] text-primary uppercase hover:bg-[#dc64780f]">Save</button>
                                                </div>
                                                <div onClick={() => setEditingIdentifierId(null)} className="cursor-pointer hover:bg-gray-100 py-2 px-1 rounded-full">
                                                    <XMarkIcon className="w-6 h-6 text-gray-500" />
                                                </div>
                                            </div>
                                            :
                                            <div className='flex items-center gap-6'>
                                                <div onClick={() => selectPeopleNameForEditing(identifier.identifierValue, identifier.id)} className='p-2 hover:bg-gray-100 rounded-full cursor-pointer'>
                                                    <PencilIcon className='w-6 h-6 text-gray-500' />
                                                </div>
                                                <div onClick={() => handleDeleteModal(identifier.id)} className='p-2 hover:bg-gray-100 rounded-full cursor-pointer'>
                                                    <TrashIcon className='w-6 h-6 text-gray-500' />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
                    {openCreateIdentifier &&
                        <div className='flex items-center h-[50px] py-4 p-6 text-[#212121] font-light bg-white text-xs'>
                            <div className='flex-[1_1_200px] mr-[10px] text-xs text-left'>
                                <span className='inline-block max-w-full overflow-hidden whitespace-nowrap'>{selectedIdentifier}</span>
                            </div>
                            <div className='flex-[1_1_200px] text-xs text-left'>
                                <input type='text' defaultValue={createIdentifierValue} onChange={handleChangeIdentifier} className='w-2/3 border-b-2 py-1 outline-none focus:border-b-primary hover:border-b-primary' />
                            </div>
                            <div className='flex-[0_1_200px] mr-[20px] text-xs text-right relative'>
                                <div className='inline-block max-w-full overflow-hidden whitespace-nowrap uppercase'>
                                    <div className="flex items-center gap-2">
                                        <div>
                                            <button onClick={createIdentifierData} className="bg-transparent py-2 px-4 rounded-primary text-[14px] text-primary uppercase hover:bg-[#dc64780f]">Save</button>
                                        </div>
                                        <div onClick={() => setOpenCreateIdentifier(false)} className="cursor-pointer hover:bg-gray-100 py-2 px-1 rounded-full">
                                            <XMarkIcon className="w-6 h-6 text-gray-500" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {deleteModal && <>
                <Transition.Root show={deleteModal} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                                        <div>
                                            <div className='text-lg font-normal px-4 pt-5'>
                                                <h1>Remove Identifier</h1>
                                                <p className='text-sm font-light py-4 text-[#757575] flex'>Are you sure you want to remove this identifier from the Product?</p>
                                            </div>
                                        </div>
                                        <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                                            <div>
                                            </div>
                                            <div className='flex items-center gap-4'>
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase outline-none hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                                    onClick={handleDeleteModal}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-white bg-primary uppercase hover:bg-primary/80 sm:col-start-1 sm:mt-0"
                                                    onClick={deleteAddedIdentifierData}
                                                >
                                                    Confirm
                                                </button>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </>}
        </>
    )
}
