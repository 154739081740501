import axios from "axios";
import { cleanHeaders } from "../clean-headers";

const getGenres = async (params) => {
  const headers = cleanHeaders(params);

  try {
    return await axios.get(
        "https://music.videomite.tech/api/genre",
        {headers}
    ).then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

export { getGenres };
