import axios from "axios";
import { cleanHeaders } from "../clean-headers";

const getPeople = async (params) => {
  const headers = cleanHeaders(params);

  try {
    return await axios.get(
        "https://music.videomite.tech/api/person",
        {headers}
    ).then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

export { getPeople };
