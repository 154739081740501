import {
  CalendarDaysIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PencilIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
  MinusSmallIcon,
  PlusSmallIcon, UserIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Header from "../Header";
import DeleteProducts from "./DeleteProducts";
import PlakIcon from "../../assets/icons/PlakIcon";
import Loading from "../Loading";
import DatePicker from "react-datepicker";
import { Dialog, Transition, Disclosure } from "@headlessui/react";
import { setOpenModal } from "../../stores/modal";
import { getCode, getName, getNames, getCodes } from "country-list";
import { debounce } from "lodash";
import { getAssets } from "../../utils/lib/get-assets";
import { getLabels } from "../../utils/lib/get-labels";
import { getPartnerIds } from "../../utils/lib/get-partners";
import { getSubgenre } from "../../utils/lib/get-subgenre";
import { getArtists } from "../../utils/lib/get-artists";
import { getGenres } from "../../utils/lib/get-genres";
import { getProduct } from "../../utils/lib/get-product";
import { useCookies } from "react-cookie";

const initialFormModel = {
  title: "",
  createdTime: "",
  productAssets: [],
  productArtists: [],
  releaseVersion: "",
  label: "",
  format: "",
  totalVolumes: "",
  isCompilation: "",
  explicitness: "",
  catalogNumber: "",
  consumerReleaseDate: "",
  upc: "",
  recordingYear: "",
  recordingLocation: "",
  p_CopyrightYear: "",
  p_CopyrightText: "",
  c_CopyrightYear: "",
  c_CopyrightText: "",
  genre: "",
  preorderDate: "",
  subgenre: "",
  alternateGenre: "",
  alternateSubgenre: "",
  albumNotes: "",
  labelCopyInfo: "",
  catalogTier: "",
  translations: [],
  territories: [],
  labelId: "",
  originalReleaseDate: "",
  genderId: "",
};

export default function ProductDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { credential, partnerIds } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(initialFormModel);
  const [title, setTitle] = useState("");
  const [createdTime, setCreatedTime] = useState("");
  const [assets, setAssets] = useState([]);
  const [addAssetsModal, setAddAssetsModal] = useState(false);
  const [searchAssetQuery, setSearchAssetQuery] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [artistProducts, setArtistProducts] = useState([]);
  const [assetInProduct, setAssetInProduct] = useState([]);
  const navigate = useNavigate();
  const [assetId, setAssetId] = useState("");
  const [releaseVersion, setReleaseVersion] = useState("");
  const [label, setLabel] = useState([]);
  const [format, setFormat] = useState("");
  const [formatOptions, setFormatOptions] = useState([
    "ALBUM",
    "SINGLE",
    "EP",
    "BOXSET",
  ]);
  const [formatModal, setFormatModal] = useState(false);
  const [totalVolumes, setTotalVolumes] = useState("");
  const [isCompilation, setIsCompilation] = useState();
  const [explicitness, setExplicitness] = useState("");
  const [explicitnessModal, setExplicitnessModal] = useState(false);
  const [explicitnessOptions, setExplicitnessOptions] = useState([
    "NotExplicit",
    "Explict",
    "Clean",
  ]);
  const [catalogNumber, setCatalogNumber] = useState();
  const [consumerReleaseDate, setConsumerReleaseDate] = useState();
  const [upc, setUpc] = useState();
  const [recordingYear, setRecordingYear] = useState("");
  const [recordingLocation, setRecordingLocation] = useState("");
  const [p_CopyrightYear, setP_CopyrightYear] = useState("");
  const [p_CopyrightText, setP_CopyrightText] = useState("");
  const [c_CopyrightYear, setC_CopyrightYear] = useState("");
  const [c_CopyrightText, setC_CopyrightText] = useState("");
  const [genre, setGenre] = useState();
  const [preorderDate, setPreorderDate] = useState();
  const [subgenre, setSubgenre] = useState(null);
  const [alternateGenre, setAlternateGenre] = useState(null);
  const [alternateSubgenre, setAlternateSubgenre] = useState(null);
  const [albumNotes, setAlbumNotes] = useState("");
  const [albumNotesModal, setAlbumNotesModal] = useState(false);
  const [labelCopyInfo, setLabelCopyInfo] = useState("");
  const [labelCopyInfoModal, setLabelCopyInfoModal] = useState(false);
  const [catalogTier, setCatalogTier] = useState("");
  const [translations, setTranslations] = useState([]);
  const [territories, setTerritories] = useState([]);
  const [labelId, setLabelId] = useState("");
  const [originalReleaseDate, setOriginalReleaseDate] = useState();
  const [genreId, setGenreId] = useState("");
    const [cookie] = useCookies(["selectedPartnerId"]);


  let headers = {
    Authorization: `Bearer ${credential}`,
    PartnerId: getPartnerIds(partnerIds),
  };

  // Fetch product details
  useEffect(() => {
    fetchProduct();
  }, []);

  const fetchProduct = async () => {
    try {
      const header = {
        ...headers,
        partnerId: cookie.selectedPartnerId,
      };

      const product = await getProduct(header, id);
      setProduct(product);
      setLoading(false);
    } catch (error) {
      toast.error("API isteği sırasında bir hata oluştu:", error);
    }
  };

  const productTitle = product?.title;
  const productCreatedTime = product?.createdTime;
  const productAssets = product?.productAssets;
  const productArtists = product?.productArtists;
  const productReleaseVersion = product?.releaseVersion;
  const productLabel = product?.label?.name;
  const productFormat = product?.format;
  const productTotalVolumes = product?.totalVolumes;
  const productIsCompilation = product?.isCompilation;
  const productExplicitness = product?.explicitness;
  const productCatalogNumber = product?.catalogNumber;
  const productConsumerReleaseDate = product?.consumerReleaseDate;
  const productUpc = product?.upc;
  const productRecordingYear = product?.recordingYear;
  const productRecordingLocation = product?.recordingLocation;
  const productp_CopyrightYear = product?.p_CopyrightYear;
  const productp_CopyrightText = product?.p_CopyrightText;
  const productc_CopyrightYear = product?.c_CopyrightYear;
  const productc_CopyrightText = product?.c_CopyrightText;
  const productGenre = product?.genre?.name;
  const productPreOrderDate = product?.preorderDate;
  const productSubgenre = product?.subgenre?.name;
  const productAlternateGenre = product?.alternateGenre?.name;
  const productAlternateSubgenre = product?.alternateSubgenre?.name;
  const productAlbumNotes = product?.albumNotes;
  const productLabelCopyInfo = product?.labelCopyInfo;
  const productCatalogTier = product?.catalogTier;
  const productTranslations = product?.translations;
  const productTerritories = product?.territories;
  const productOriginalReleaseDate = product?.originalReleaseDate;
  const productGenreId = product?.genreId;
  const productLanguage = product?.defaultLanguage;
  const productIsBarcodeGeneratable = product?.isBarcodeGeneratable;
  const productIsBarcodeAutoAssigned = product?.isBarcodeAutoAssigned;

  useEffect(() => {
    if (!productTitle) {
      return;
    }

    const dateObj = new Date(productCreatedTime);
    const formattedDate = `${dateObj.getFullYear()}-${(
      "0" +
      (dateObj.getMonth() + 1)
    ).slice(-2)}-${("0" + dateObj.getDate()).slice(-2)}`;

    setTitle(productTitle);
    setCreatedTime(formattedDate);
    setAssetInProduct(productAssets);
    setArtistToProduct(productArtists);
    setArtistProducts(productArtists);
    setReleaseVersion(productReleaseVersion);
    setLabel(productLabel);
    setFormat(productFormat);
    setTotalVolumes(productTotalVolumes);
    setIsCompilation(productIsCompilation);
    setExplicitness(productExplicitness);
    setCatalogNumber(productCatalogNumber);
    setConsumerReleaseDate(productConsumerReleaseDate);
    setUpc(productUpc);
    setRecordingYear(productRecordingYear);
    setRecordingLocation(productRecordingLocation);
    setP_CopyrightYear(productp_CopyrightYear);
    setP_CopyrightText(productp_CopyrightText);
    setC_CopyrightYear(productc_CopyrightYear);
    setC_CopyrightYear(productc_CopyrightYear);
    setGenre(productGenre);
    setPreorderDate(productPreOrderDate);
    setSubgenre(productSubgenre);
    setAlternateGenre(productAlternateGenre);
    setAlternateSubgenre(productAlternateSubgenre);
    setAlbumNotes(albumNotes);
    setLabelCopyInfo(productLabelCopyInfo);
    setCatalogTier(productCatalogTier);
    setTranslations(productTranslations);
    setTerritories(productTerritories);
    setOriginalReleaseDate(productOriginalReleaseDate);
    setGenreId(productGenreId);
    setIsBarcodeGeneratable(productIsBarcodeGeneratable);
  }, [
    productTitle,
    productCreatedTime,
    productAssets,
    productArtists,
    productReleaseVersion,
    productLabel,
    productFormat,
    productTotalVolumes,
    productIsCompilation,
    productExplicitness,
    productCatalogNumber,
    productConsumerReleaseDate,
    productUpc,
    productRecordingYear,
    productRecordingLocation,
    productp_CopyrightYear,
    productp_CopyrightText,
    productc_CopyrightYear,
    productc_CopyrightText,
    productGenre,
    productPreOrderDate,
    productSubgenre,
    productAlternateGenre,
    productAlternateSubgenre,
    productAlbumNotes,
    productLabelCopyInfo,
    productCatalogTier,
    productTranslations,
    productTerritories,
    productGenreId,
    productIsBarcodeGeneratable,
  ]);

  //get assets
  useEffect(() => {
    const fetchAssetData = async () => {
      try {
        const assets = await getAssets(headers);
        setLabel(assets);
        setLoading(false);
      } catch (error) {
        console.error("API isteği sırasında bir hata oluştu:", error);
        setLoading(true);
      }
    };
    fetchAssetData();
  }, []);

  const handleAssetSearch = (event) => {
    const { value } = event.target;
    setSearchAssetQuery(value);
  };

  const handleSelectedAsset = async (id) => {
    setSelectedAsset(id);
    const isSuccess = await addAssetToProduct(id);

    if (isSuccess) {
      // Yeni asseti assets listesine ekleyin
      const newAssetList = [...assets];
      const newAsset = newAssetList.find((asset) => asset.id === id);
      setAssetInProduct((prevState) => [...prevState, newAsset]);
    }
  };

  //add asset request
  const addAssetToProduct = async (assetId) => {
    const header = {
      ...headers,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const response = await axios.post(
        `https://music.videomite.tech/api/product/${id}/assetRelation`,
        {
          assetId: assetId,
        },
        {
          headers: header,
        }
      );
      const addedAsset = response.data.productAssets;
      setAssetInProduct(addedAsset);
      setAddAssetsModal(false);
      toast.success("Added asset successfully!");
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error(error.request);
      } else {
        toast.error("API isteğinde hata meydana geldi.", error.message);
      }
    }
  };

  const handleAssetModal = () => {
    setAddAssetsModal(!addAssetsModal);
    setSearchAssetQuery("");
  };

  // delete asset from products
  const assetToBeDeleted = (assetId) => {
    setAssetId(assetId);
    deleteAssetFromProduct(assetId);
  };

  const deleteAssetFromProduct = async (assetId) => {
    try {
      // DELETE
      const header = {
        ...headers,
        partnerId: cookie.selectedPartnerId,
      };
      const response = await axios.delete(
        `https://music.videomite.tech/api/product/${id}/assetRelation/${assetId}`,
        { headers: header }
      );
      const deletedProduct = response.data.productAssets;
      setAssetInProduct(deletedProduct);
      toast.success("Asset deleted successfully");
      setAddAssetsModal(false);
    } catch (error) {
      toast.error("Product silinirken bir hata oluştu:", error);
    }
  };

  //get labels
  const [labelModal, setLabelModal] = useState(false);
  const [searchLabelQuery, setSearchLabelQuery] = useState("");
  const [filteredLabel, setFilteredLabel] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState();
  const [labelList, setLabelList] = useState([]);

  useEffect(() => {
    const fetchLabelData = async () => {
      try {
        const labels = await getLabels(headers);
        setLabelList(labels);
        setLoading(false);
      } catch (error) {
        console.error("API isteği sırasında bir hata oluştu:", error);
        setLoading(true);
      }
    };
    fetchLabelData();
  }, []);

  const handleLabelSearch = (event) => {
    const { value } = event.target;
    setSearchLabelQuery(value);
  };

  useEffect(() => {
    if (!labelList) return;

    if (!Array.isArray(labelList)) {
      console.error("labelList dizi olmalı:", labelList);
      return;
    }
    const addedLabelName = labelList.map((lbl) => {
      return {
        id: lbl.id,
        name: lbl.name.toLowerCase(),
      };
    });

    const searchFilteredLabels = addedLabelName.filter((lbl) =>
      lbl.name.includes(searchLabelQuery.toLowerCase())
    );

    setFilteredLabel(searchFilteredLabels);
  }, [searchLabelQuery, labelList]);

  const handleSelectedLabel = (id) => {
    setSelectedLabel(id);
  };

  // artist
  const [artistList, setArtistList] = useState();
  const [addArtistDropdownModal, setAddArtistDropdownModal] = useState(false);
  const [searchArtistQuery, setSearchArtistQuery] = useState("");
  const [filteredArtist, setFilteredArtist] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState();
  const [artistToProduct, setArtistToProduct] = useState([]);
  const [artistId, setArtistId] = useState("");
  const [artistRole, setArtistRole] = useState("");
  const [isPrimary, setIsPrimary] = useState(false);
  const [artistsForProducts, setArtistsForProducts] = useState([]);

  const handleAddArtistDropdownModal = () => {
    setAddArtistDropdownModal(false);
    setSearchArtistQuery("");
  };


  const handleArtistSearch = (event) => {
    const { value } = event.target;
    setSearchArtistQuery(value);
  };

  const handleSelectedArtist = (id) => {
    setSelectedArtist(id);
    addArtistToProduct(id);
  };


  useEffect(() => {
    if (addArtistDropdownModal) {
      fetchArtistData();
    }
  }, [addArtistDropdownModal]);

  //get artists
  const fetchArtistData = async () => {
    try {
      const artists = await getArtists(headers);
      setArtistList(artists);
      setLoading(false);

      const artistsRole = artists.map((artist) => ({
        ...artist,
        isPrimary: artist.artistRole === "Primary",
      }));
      setArtistsForProducts(artistsRole);
    } catch (error) {

      toast.error("API isteği sırasında bir hata oluştu:", error);
    }
  };


  useEffect(() => {
    if (addAssetsModal) {
      fetchAssetsData();
    }
  }, [addAssetsModal]);

  //get artists
  const fetchAssetsData = async () => {
    try {
      const assets = await getAssets(headers);
      setAssets(assets);
      setLoading(false);
    } catch (error) {

      toast.error("API isteği sırasında bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    if (!artistList) return;

    if (!Array.isArray(artistList)) {
      console.error("artistList dizi olmalı:", artistList);
      return;
    }
    const addedArtistNames = artistList.map((artist) => {
      return {
        id: artist.id,
        name: artist.name.toLowerCase(),
      };
    });

    const searchFilteredArtists = addedArtistNames.filter((artist) =>
      artist.name.includes(searchArtistQuery.toLowerCase())
    );

    setFilteredArtist(searchFilteredArtists);
  }, [searchArtistQuery, artistList]);

  //add artists request
  const addArtistToProduct = async (selectedArtist) => {
    const header = {
      ...headers,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };

    try {
      const response = await axios.post(
        `https://music.videomite.tech/api/product/${id}/artistRelation`,
        {
          artistId: selectedArtist,
        },
        {
          headers: header,
        }
      );
      const res = response.data.productArtists;
      setArtistProducts(res);
      setAddArtistDropdownModal(false);
      toast.success("Added artist successfully");
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error(error.request);
      } else {
        toast.error("API isteğinde hata meydana geldi.", error.message);
      }
    }
  };

  // delete artist from products
  const artistToBeDeleted = (artistId) => {
    setArtistId(artistId);
    deleteArtistFromProduct(artistId);
  };

  const deleteArtistFromProduct = async (artistId) => {
    try {
      // DELETE
      const header = {
        ...headers,
        partnerId: cookie.selectedPartnerId,
      };
      const response = await axios.delete(
        `https://music.videomite.tech/api/product/${id}/artistRelation/${artistId}`,
        { headers: header }
      );
      const deletedProduct = response.data.productArtists;
      setArtistToProduct(deletedProduct);

      setArtistProducts(deletedProduct);
      setAddArtistDropdownModal(false);
      toast.success("Artist deleted successfully");
    } catch (error) {
      toast.error("Artist silinirken bir hata oluştu:", error);
    }
  };

  // change artistRole
  const handleRoleChange = (artistId, currentRole) => {
    const newRole = currentRole === "Primary" ? "Featuring" : "Primary";
    setArtistsForProducts(
      productArtists.map((artist) =>
        artist.id === artistId ? { ...artist, artistRole: newRole } : artist
      )
    );
    changeArtistRole(artistId, newRole);
  };

  const changeArtistRole = (artistId, newRole) => {
    setArtistId(artistId);
    changeArtistRoleRequest(artistId, newRole);
  };

  //add artists request
  const changeArtistRoleRequest = async (artistId, newRole) => {
    const header = {
      ...headers,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };

    try {
      const response = await axios.put(
        `https://music.videomite.tech/api/product/${id}/artistRelation/${artistId}`,
        {
          artistRole: newRole,
        },
        {
          headers: header,
        }
      );
      const updatedArtistRole = response.data;
      setArtistRole(updatedArtistRole);
      toast.success("Updated artist role successfully");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error(error.request);
      } else {
        toast.error("API isteğinde hata meydana geldi.", error.message);
      }
    }
  };

  //translations
  const [addTranslation, setAddTranslation] = useState(false);
  const [languageNames, setLanguageNames] = useState([]);
  const [filteredLanguage, setFilteredLanguage] = useState();
  const [searchQueryTranslation, setSearchQueryTranslation] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [openTranslationModal, setOpenTranslationModal] = useState(false);
  const [editTranslation, setEditTranslation] = useState(false);
  const [editingTranslationName, setEditingTranslationName] = useState(null);
  const [editedProductTitle, setEditedProductTitle] = useState();
  const [editAddedTranslation, setEditAddedTranslation] = useState(false);
  const [editingTranslationId, setEditingTranslationId] = useState("");
  const [closeDeleteModal, setCloseDeleteModal] = useState(false);
  const [deleteModalTranslation, setDeleteModalTranslation] = useState(false);
  const [deletingTranslationId, setDeletingTranslationId] = useState("");
  const [handleModalT, setHandleModalT] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const initialContinentData = {
    asiaSubterritories: [],
    oceaniaSubterritories: [],
    europeSubterritories: [],
    americasSubterritories: [],
    africaSubterritories: [],
    isWorldSelected: false,
    isAllAsiaSelected: false,
    isAllOceaniaSelected: false,
    isAllEuropeSelected: false,
    isAllAmericasSelected: false,
    isAllAfricaSelected: false,
  }

  // territory
  const [continentData, setContinentData] = useState(initialContinentData);

  const handleTranslationSearch = (event) => {
    const { value } = event.target;
    setSearchQueryTranslation(value);
  };

  const handleSelectedLanguage = (language) => {
    setSelectedLanguage(language);
    setOpenTranslationModal(true);
  };

  const closeTranslationModal = () => {
    setAddTranslation(false);
    setSearchQueryTranslation("");
    setEditTranslation(false);
  };

  const closeModalT = () => {
    setOpenTranslationModal(false);
    setHandleModalT(false);
    setEditTranslation(false);
    setEditingTranslationName("");
  };

  const closeModalDeleteTranslation = () => {
    setCloseDeleteModal(false);
    setEditTranslation(false);
    setEditedProductTitle("");
  };

  function selectProductNameForEditing(productTitle, translationId) {
    setEditAddedTranslation(!editAddedTranslation);
    setEditingTranslationName(productTitle);
    setEditingTranslationId(translationId);
  }

  const handleDeleteModal = (identifierId) => {
    setDeleteModal(!deleteModal);
  };

  useEffect(() => {
    const fetchLang = async () => {
      try {
        const response = await axios.get(
          `https://music.videomite.tech/api/language`,
          {
            headers,
          }
        );
        const languages = response.data;
        setLanguageNames(languages);

        setLoading(false);
      } catch (error) {
        console.error("API isteği sırasında bir hata oluştu:", error);
      }
    };

    fetchLang();
  }, [addTranslation]);

  useEffect(() => {
    if (!productTranslations) return;

    if (!Array.isArray(productTranslations)) {
      console.error("productTranslations dizi olmalı:", productTranslations);
      return;
    }

    const addedLanguageNames = productTranslations.map((translation) =>
      translation.language.toLowerCase()
    );

    const searchFilteredLanguages = languageNames.filter(
      (lang) =>
        lang.toLowerCase().includes(searchQueryTranslation.toLowerCase()) &&
        !addedLanguageNames.includes(lang.toLowerCase())
    );

    setFilteredLanguage(searchFilteredLanguages);
  }, [searchQueryTranslation, productTranslations]);

  //edited added translation data
  const editedAddedTranslationData = async () => {
    const header = {
      ...headers,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };

    try {
      const response = await axios.put(
        `https://music.videomite.tech/api/product/${id}/translation/${editingTranslationId}`,
        {
          productTitle: editingTranslationName,
          productReleaseVersion: "",
        },
        {
          headers: header,
        }
      );
      const updatedTranslationName = response.data;
      setTranslations(updatedTranslationName);
      setLoading(false);
      setEditAddedTranslation(false);
      window.location.reload();
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error);
      setLoading(true);
    }
  };

  //delete added translation data
  const deleteAddedTranslationData = async () => {
    const header = {
      ...headers,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };

    try {
      const response = await axios.delete(
        `https://music.videomite.tech/api/product/${id}/translation/${deletingTranslationId}`,
        {
          headers: header,
        }
      );
      const deleteTranslationData = response.data;
      setTranslations(deleteTranslationData);
      setLoading(false);
      handleDeleteModal();
      window.location.reload();
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error);
      setLoading(true);
    }
  };

  const handleDeleteModalTranslation = (translationId) => {
    setDeleteModalTranslation(!deleteModalTranslation);
    setDeletingTranslationId(translationId);
  };

  const openEditTranslationModal = () => {
    setEditTranslation(true);
  };

  const handleChangeTranslation = (e) => {
    const { value } = e.target;
    setEditedProductTitle(value);
    setEditingTranslationName(value);
  };

  //post translation data
  const createTranslationData = async () => {
    const header = {
      ...headers,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const response = await axios.post(
        `https://music.videomite.tech/api/product/${id}/translation`,
        {
          language: selectedLanguage,
          productTitle: editedProductTitle,
          productReleaseVersion: "",
        },
        {
          headers: header,
        }
      );
      const createdTranslation = response.data;
      setTranslations(createdTranslation);
      dispatch(setOpenModal(false));
      closeModalT();
      closeTranslationModal();
      window.location.reload();
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error);
    }
  };


  // Barcode
  const [barcodeNumberModal, setBarcodeNumberModal] = useState(false);
  const [isBarcodeAssigned, setIsBarcodeAssigned] = useState(false);
  const [isBarcodeGeneratable, setIsBarcodeGeneratable] = useState(false);

  const handleOnBarcodeAssignAction = () => {
    setIsBarcodeAssigned(!isBarcodeAssigned)
  };

  const handleOnBarcodeAssign = async () => {
    setLoading(true);
    setIsBarcodeGeneratable(false);
    debouncedUpdate({ ...product, isBarcodeAutoAssigned: isBarcodeAssigned });
    setBarcodeNumberModal(false);
  };

  //Territories
  const [territoriesModal, setTerritoriesModal] = useState(false);
  const [regionCountries, setRegionCountries] = useState({});
  const [selectedCountries, setSelectedCountries] = useState({});
  const [openRegion, setOpenRegion] = useState("");
  const [allSelected, setAllSelected] = useState({});
  const [allRegionsSelected, setAllRegionsSelected] = useState(false);

  useEffect(() => {
    fetch("https://music.videomite.tech/api/Subterritory", { headers})
      .then((response) => response.json())
      .then((countries) => {
        let regions = {};
        let selections = {};
        let selectAll = {};

        countries.forEach((country) => {
          let region = country.region;
          if (region) {
            if (!regions[region]) {
              regions[region] = [];
              selectAll[region] = productTerritories[`isAll${region}Included`];
            }
            regions[region].push({
              name: country.name,
            });
            selections[country.name] = false;



            const subterritoriesInRegion = productTerritories[`${region.toLowerCase()}Subterritories`];
            const isCountryInRegion = subterritoriesInRegion && subterritoriesInRegion.includes(country.name);

            if (isCountryInRegion) {
              selections[country.name] = true;

              handleCountrySelection(region, country.name);

            } else {
              selections[country.name] = false;
            }
          }
        });

        setContinentData((prevData) => ({
          ...prevData,
          [`isWorldSelected`]: productTerritories?.isAllWorldIncluded,
          [`isAllOceaniaSelected`]: productTerritories?.isAllOceaniaIncluded,
          [`isAllEuropeSelected`]: productTerritories?.isAllEuropeIncluded,
          [`isAllAsiaSelected`]: productTerritories?.isAllAsiaIncluded,
          [`isAllAmericasSelected`]: productTerritories?.isAllAmericasIncluded,
          [`isAllAfricaSelected`]: productTerritories?.isAllAfricaIncluded,
        }));

        setRegionCountries(regions);
        setSelectedCountries(selections);
        setAllSelected(selectAll);
        setAllRegionsSelected(productTerritories?.isAllWorldIncluded);
      })
      .catch((err) => console.error(err));
  }, [productTerritories]);

  const handleRegionClick = (region) => {
    if (openRegion === region) {
      setOpenRegion("");
    } else {
      setOpenRegion(region);
    }
  };

  //add asset request
  const addTerritories = async () => {
    const header = {
      ...headers,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const response = await axios.post(
          `https://music.videomite.tech/api/product/${id}/territories`,
          continentData,
          {
            headers: header,
          }
      );

      toast.success("Added territory successfully!");
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error(error.request);
      } else {
        toast.error("API isteğinde hata meydana geldi.", error.message);
      }
    }
  };

  const handleCountrySelection = (region, country) =>  {
    const continent = `${region.toLowerCase()}Subterritories`;

    setContinentData((prevData) => {
      const countryIndex = prevData[continent].indexOf(country);

      if (countryIndex === -1) {
        return {
          ...prevData,
          [continent]: [...prevData[continent], country],
        };
      } else {
        return {
          ...prevData,
          [continent]: prevData[continent].filter((c) => c !== country),
        };
      }
    });
  }

  const handleCheckChange = (event, region, country) => {
    const newSelected = {
      ...selectedCountries,
      [event.target.name]: event.target.checked,
    };
    setSelectedCountries(newSelected);

    const regionSelected = regionCountries[region].every(
      (country) => newSelected[country.name]
    );
    setAllSelected({ ...allSelected, [region]: regionSelected });

    const allRegionsCheck = Object.values(allSelected).every((val) => val);
    setAllRegionsSelected(allRegionsCheck);

    handleCountrySelection(region, country);
  };

  const handleAllCheckChange = (event, region) => {
    const regionSelectStatus = event.target.checked;
    const newSelected = { ...selectedCountries };
    regionCountries[region].forEach((country) => {
      newSelected[country.name] = regionSelectStatus;

      handleCountrySelection(region, country.name);

    });
    setSelectedCountries(newSelected);
    setAllSelected({ ...allSelected, [region]: regionSelectStatus });

    const allRegionsCheck = Object.values(allSelected).every((val) => val);
    setAllRegionsSelected(allRegionsCheck);

    setContinentData((prevData) => ({
      ...prevData,
      [`isAll${region}Selected`]: !prevData[`isAll${region}Selected`],
    }));
  };

  const handleAllRegionsCheckChange = (event) => {
    const regionsSelectStatus = event.target.checked;
    const newSelected = { ...selectedCountries };
    const newAllSelected = { ...allSelected };
    for (let region in regionCountries) {
      regionCountries[region].forEach((country) => {
        newSelected[country.name] = regionsSelectStatus;
      });
      newAllSelected[region] = regionsSelectStatus;
    }
    setSelectedCountries(newSelected);
    setAllSelected(newAllSelected);
    setAllRegionsSelected(regionsSelectStatus);

    if (!event.target.checked) {
      setContinentData(initialContinentData)
    } else {
      setContinentData((prevData) => ({
        ...prevData,
        isWorldSelected: !prevData.isWorldSelected,
      }));
    }

  };

  // metadata
  const onChange = (evt) => {
    const { name, value } = evt.target;
    const newProduct = { ...product, [name]: value };
    setProduct(newProduct);
    debouncedUpdate(newProduct);
  };

  const onSaveClick = () => {
    debouncedUpdate(product);
  };

  const onSendForReviewClick = () => {
    debouncedSendForReview();
  }

  const debouncedUpdate = useCallback(
    debounce(async (product) => {
      try {
        const header = {
          ...headers,
          partnerId: cookie.selectedPartnerId,
        };

        const changedProduct = {
          ...product,
          title: product.title,
          releaseVersion: product.releaseVersion,
          recordingYear: product.recordingYear,
          totalVolumes: product.totalVolumes,
          upc: product.upc,
          catalogNumber: product.catalogNumber,
          recordingLocation: product.recordingLocation,
          p_CopyrightYear: product.p_CopyrightYear,
          p_CopyrightText: product.p_CopyrightText,
          c_CopyrightYear: product.c_CopyrightYear,
          c_CopyrightText: product.c_CopyrightText,
          albumNotes: product.albumNotes,
          labelCopyInfo: product.labelCopyInfo,
          catalogTier: product.catalogTier,
          isCompilation: product.isCompilation,
          format: product.format,
          explicitness: product.explicitness,
          labelId: product.labelId,
          consumerReleaseDate: product.consumerReleaseDate,
          preorderDate: product.preorderDate,
          originalReleaseDate: product.originalReleaseDate,
          genreId: product.genreId,
          subgenreId: product.subgenreId,
          alternateGenreId: product.alternateGenreId,
          alternateSubgenreId: product.alternateSubgenreId,
          defaultLanguage: product.defaultLanguage,
          isBarcodeAutoAssigned: product.isBarcodeAutoAssigned,
        };

        await axios.put(
          `https://music.videomite.tech/api/product/${id}`,
          changedProduct,
          { headers: header }
        );

        if (product.isBarcodeAutoAssigned) {
          await fetchProduct();
        } else {
          setProduct(changedProduct);
        }
        setLoading(false);
        toast.success("Product updated successfully");
      } catch (error) {
        const errorMessage = error.response?.data?.message || "Unknown error";
        toast.error(`Error: ${errorMessage}`);
      }
    }, 1000),
    []
  );

  const debouncedSendForReview = useCallback(
    debounce(async () => {
      try {
        const header = {
          ...headers,
          partnerId: cookie.selectedPartnerId,
        };

        const sendForReviewPayload = {
          productId: id
        };

        await axios.post(
          `https://music.videomite.tech/api/release/sendForReview`,
          sendForReviewPayload,
          { headers: header }
        );

        setLoading(false);
        toast.success("Product is sent for review successfully");
      } catch (error) {
        const errorMessage = error.response?.data?.message || "Unknown error";
        toast.error(`Error: ${errorMessage}`);
      }
    }, 1000),
    []
  );

  const handleCompilationChange = (newValue) => {
    setIsCompilation(newValue);
    debouncedUpdate({ ...product, isCompilation: newValue });
  };
  const handleFormatSelection = (format) => {
    setFormat(format);
    setFormatModal(false);
    debouncedUpdate({ ...product, format: format });
  };

  const handleExplicetSelection = (explicitness) => {
    setExplicitness(explicitness);
    setExplicitnessModal(false);
    debouncedUpdate({ ...product, explicitness: explicitness });
  };

  const handleLabelSelection = (labelId, labelName) => {
    setLabelId(labelId);
    setLabelModal(false);
    debouncedUpdate({ ...product, labelId: labelId, label: { name: labelName } });
  };

  const formatDate = (date) => {
    let d = new Date(date);

    // Tarih kontrolü
    if (isNaN(d.getTime())) {
      console.error(`Invalid date value: ${date}`);
      return date;
    }

    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handleConsumerReleaseDateSelection = (date) => {
    let formattedDate = formatDate(date);
    setConsumerReleaseDate(formattedDate);
    debouncedUpdate({ ...product, consumerReleaseDate: formattedDate });
  };

  const formatDateForPreOrder = (date) => {
    let d = new Date(date);

    // Tarih kontrolü
    if (isNaN(d.getTime())) {
      console.error(`Invalid date value: ${date}`);
      return date;
    }

    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handlePreOrderDateSelection = (date) => {
    let formattedDate = formatDateForPreOrder(date);
    setPreorderDate(formattedDate);
    debouncedUpdate({ ...product, preorderDate: formattedDate });
  };

  const formatDateOriginalRelease = (date) => {
    let d = new Date(date);

    // Tarih kontrolü
    if (isNaN(d.getTime())) {
      console.error(`Invalid date value: ${date}`);
      return date;
    }

    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handleOriginalReleaseDateSelection = (date) => {
    let formattedDate = formatDateOriginalRelease(date);
    setOriginalReleaseDate(formattedDate);
    debouncedUpdate({ ...product, originalReleaseDate: formattedDate });
  };

  //genre
  const [allGenre, setAllGenre] = useState([]);
  const [genreModal, setGenreModal] = useState(false);
  const [filteredGenre, setFilteredGenre] = useState([]);
  const [searchGenreQuery, setSearchGenreQuery] = useState("");
  const [selectedGenre, setSelectedGenre] = useState();
  const [selectedGenreName, setSelectedGenreName] = useState("");
  const [selectedAlternateGenreName, setSelectedAlternateGenreName] =
    useState("");
  const [alternateGenreModal, setAlternateGenreModal] = useState(false);
  const [searchAlternateGenreQuery, setSearchAlternateGenreQuery] =
    useState("");
  const [filteredAlternateGenre, setFilteredAlternateGenre] = useState([]);
  const [selectedAlternateGenre, setSelectedAlternateGenre] = useState();

  // Catalog Tier
  const [catalogModal, setCatalogModal] = useState(false);
  const [selectedProductCatalogTier, setSelectedProductCatalogTier] = useState("");

  const handleSelectedProductCatalogTier = (name) => {
    setSelectedProductCatalogTier(name);
    setCatalogModal(false);
    debouncedUpdate({ ...product, catalogTier: name });
  };


  const fetchGenreData = async () => {
    try {
      const genres = await getGenres(headers);
      setAllGenre(genres);
      setLoading(false);
    } catch (error) {
      toast.error("API isteği sırasında bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    fetchGenreData();
  }, []);

  const handleGenreSearch = (event) => {
    const { value } = event.target;
    setSearchGenreQuery(value);
  };

  useEffect(() => {
    if (!allGenre) return;

    if (!Array.isArray(allGenre)) {
      console.error("allGenre dizi olmalı:", allGenre);
      return;
    }
    const addedGenreName = allGenre.map((genre) => {
      return {
        id: genre.id,
        name: genre.name.toLowerCase(),
      };
    });

    const searchFilteredGenre = addedGenreName.filter((genre) =>
      genre.name.includes(searchGenreQuery.toLowerCase())
    );

    setFilteredGenre(searchFilteredGenre);
    setFilteredAlternateGenre(searchFilteredGenre);
  }, [searchGenreQuery, allGenre]);

  const handleSelectedGenre = (id, name) => {
    setSelectedGenre(id);
    setSelectedGenreName(name);
    setGenreModal(false);
    debouncedUpdate({ ...product, genreId: id });
  };

  //subgenre
  const [allSubgenre, setAllSubgenre] = useState([]);
  const [subgenreModal, setSubgenreModal] = useState(false);
  const [searchSubgenreQuery, setSearchSubgenreQuery] = useState("");
  const [filteredSubgenre, setFilteredSubgenre] = useState([]);
  const [selectedSubgenre, setSelectedSubgenre] = useState();
  const [selectedSubgenreName, setSelectedSubgenreName] = useState("");
  const [deleteSubgenreId, setDeleteSubgenreId] = useState("");
  const [newSubgenre, setNewSubgenre] = useState("");
  const [selectedAlternateSubgenre, setSelectedAlternateSubgenre] =
    useState("");
  const [alternateSubgenreModal, setAlternateSubgenreModal] = useState(false);
  const [newAlternateSubgenre, setNewAlternateSubgenre] = useState("");
  const [filteredAlternateSubgenre, setFilteredAlternateSubgenre] = useState(
    []
  );
  const [selectedAlternateSubgenreName, setSelectedAlternateSubgenreName] = useState("");
  const [deleteAlternateSubgenreId, setDeleteAlternateSubgenreId] = useState("");

  const [metadataLanguageModal, setMetadataLanguageModal] = useState(false);
  const [selectedMetadataLanguage, setSelectedMetadataLanguage] = useState("");

  const handleSelectedMetadataLanguage = (name) => {
    setSelectedMetadataLanguage(name);
    setMetadataLanguageModal(false);
    setSearchQueryTranslation("");
    setSelectedLanguage(name);
    debouncedUpdate({ ...product, defaultLanguage: name });
  };

  const fetchSubgenreData = async () => {
    try {
      const subgenres = await getSubgenre(headers);

      setAllSubgenre(subgenres);
      setLoading(false);
    } catch (error) {
      toast.error("API isteği sırasında bir hata oluştu:", error);
    }
  };

  useEffect(() => {
    fetchSubgenreData();
  }, []);

  const handleSubgenreSearch = (event) => {
    const { value } = event.target;
    setSearchSubgenreQuery(value);
  };

  useEffect(() => {
    if (!allSubgenre) return;

    if (!Array.isArray(allSubgenre)) {
      console.error("allSubgenre dizi olmalı:", allSubgenre);
      return;
    }
    const addedSubgenreName = allSubgenre.map((subgenre) => {
      return {
        id: subgenre.id,
        name: subgenre.name.toLowerCase(),
      };
    });

    const searchFilteredSubgenre = addedSubgenreName.filter((subgenre) =>
      subgenre.name.includes(searchSubgenreQuery.toLowerCase())
    );
    setFilteredSubgenre(searchFilteredSubgenre);
    setFilteredAlternateSubgenre(searchFilteredSubgenre);
  }, [searchSubgenreQuery, allSubgenre]);

  const handleSelectedSubgenre = (id, name) => {
    setSelectedSubgenre(id);
    setSelectedSubgenreName(name);
    setSubgenreModal(false);
    debouncedUpdate({ ...product, subgenreId: id });
  };

  const deleteSubgenre = (id) => {
    setDeleteSubgenreId(id);
    deleteSubgenreFromProduct(id);
  };

  // delete subgenre
  const deleteSubgenreFromProduct = async (deletedId) => {
    try {
      // DELETE
      const header = {
        ...headers,
        partnerId: cookie.selectedPartnerId,
      };
      const response = await axios.delete(
        `https://music.videomite.tech/api/subgenre/${deletedId}`,
        { headers: header }
      );
      const deletedSubgenre = response.data;
      setAllSubgenre(deletedSubgenre);
      toast.success("Subgenre deleted successfully");
    } catch (error) {
      let errorMessage = error.response.data.message;
      toast.error("Error:" + errorMessage);
    }
  };

  const addNewSubgenre = (e) => {
    setNewSubgenre(e.target.value);
  };

  const addNewSubgenreRequest = async () => {
    const header = {
      ...headers,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };

    try {
      const response = await axios.post(
        `https://music.videomite.tech/api/subgenre`,
        {
          name: newSubgenre,
        },
        {
          headers: header,
        }
      );
      const addedSubgenre = response.data;
      setAllSubgenre(addedSubgenre);
      toast.success("Added subgenre successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      toast.error("API isteğinde hata meydana geldi.", error.message);
    }
  };

  //alternate genre
  const handleAlternateGenreSearch = (event) => {
    const { value } = event.target;
    setSearchAlternateGenreQuery(value);
  };

  const handleSelectedAlternateGenre = (id, name) => {
    setSelectedAlternateGenre(id);
    setSelectedAlternateGenreName(name);
    setAlternateGenreModal(false);
    debouncedUpdate({ ...product, alternateGenreId: id });
  };

  // alternate subgenre
  const addNewAlternateSubgenre = (e) => {
    setNewAlternateSubgenre(e.target.value);
  };

  const handleSelectedAlternateSubgenre = (id, name) => {
    setSelectedAlternateSubgenre(id);
    setSelectedAlternateSubgenreName(name);
    setAlternateSubgenreModal(false);
    debouncedUpdate({ ...product, alternateSubgenreId: id });
  };

  // delete alternate subgenre
  const deleteAlternateSubgenreFromProduct = async (deletedId) => {
    try {
      // DELETE
      const header = {
        ...headers,
        partnerId: cookie.selectedPartnerId,
      };
      const response = await axios.delete(
        `https://music.videomite.tech/api/subgenre/${deletedId}`,
        { headers: header }
      );
      const deletedSubgenre = response.data;
      setAllSubgenre(deletedSubgenre);
      toast.success("Alternate Subgenre deleted successfully");
    } catch (error) {
      let errorMessage = error.response.data.message;
      toast.error("Error:" + errorMessage);
    }
  };

  const deleteAlternateSubgenre = (id) => {
    setDeleteAlternateSubgenreId(id);
    deleteAlternateSubgenreFromProduct(id);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  const fileSelectedHandler = async (event) => {
    try {
      setImageLoading(true)
      const header = {
        ...headers,
        Authorization: `Bearer ${credential}`,
        partnerId: cookie.selectedPartnerId,
      };

      const formData = new FormData();
      formData.append('coverArtFile', event.target.files[0]);

      const response = await fetch(`https://music.videomite.tech/api/product/${id}/coverArt`, {
        method: 'POST',
        body: formData,
        headers: header
      });

      if (response.ok) {
        const responseData = await response.json();
        setSelectedFile(responseData.imageFile.url);
        setImageLoading(false)
      } else {
        setImageLoading(false)
        console.error('Dosya yükleme başarısız:', response.statusText);
      }
    } catch (error) {
      setImageLoading(false)
      console.error('Dosya yükleme hatası:', error);
    }
  };


  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {imageLoading && <Loading />}
      <ToastContainer autoClose={3000} />
      <Header />
      <DeleteProducts />
      <div className="mx-auto max-w-7xl p-3 lg:px-8">
        <div className="flex items-center gap-2 py-6">
          <div>
            <Link to="/catalog/products" className="text-[#555555] text-sm">
              Catalog
            </Link>
          </div>
          <ChevronRightIcon className="w-4 h-4 text-[#555555]" />
          <div>
            <Link to="/catalog/products" className="text-[#555555] text-sm">
              Products
            </Link>
          </div>
          <ChevronRightIcon className="w-4 h-4 text-[#555555]" />
          <div>
            <Link
              to={`/catalog/products/${id}`}
              className="text-[#555555] text-sm"
            >
              {productTitle}
            </Link>
          </div>
          <div className="flex ml-auto">
            <button
                onClick={onSaveClick}
                className="ml-6 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Save Changes
            </button>
            <button
                onClick={onSendForReviewClick}
                className="ml-6 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Send For Review
            </button>            
          </div>
        </div>

        {/* Hero Section*/}
        <div className="bg-white border border-[#e2e2e2]">
          <div className="flex gap-2 items-center">
            <div className="bg-[#f4f4f6] hover:before:content-['update'] hover:before:bottom-0 relative">
              <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-52 h-52 rounded-none cursor-pointer bg-[#dcd6d6]"
              >
                  {selectedFile || product?.imageFile?.url ? (
                      <img
                          className='w-52 h-52 object-cover shadow-inner'
                          src={selectedFile ?? product?.imageFile.url} alt="" />
                  ) : (
                      <PlakIcon className="w-52 h-52 p-2" />
                  )}
                <input onChange={fileSelectedHandler} id="dropzone-file" type="file" className="hidden" />
              </label>
            </div>
            <div className="block py-2 px-4">
              <h1 className="font-semibold">{productTitle}</h1>
              <div className="flex items-center gap-1 my-2 text-xs text-[#555555B3]">
                <span>DATE ADDED:</span>
                <span>{createdTime}</span>
              </div>
            </div>
          </div>
          <div className="relative shadow-md border border-[#E2E2E2]">
            <div className="w-full text-sm text-left">
              <div className="flex items-center h-[50px] py-3 p-6 text-[#212121] font-light border-b border-[#E2E2E2] bg-white text-xs">
                <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                  <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                    Title
                  </div>
                </div>
                <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                  <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                    Track Version
                  </div>
                </div>
                <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                  <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                    Artist
                  </div>
                </div>
                <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                  <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                    Isrc
                  </div>
                </div>
                <div className="relative flex-[1_1_200px] mr-[10px] text-xs text-left">
                  <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                    <div
                      onClick={handleAssetModal}
                      className="flex items-center gap-2 cursor-pointer  hover:bg-gray-100 px-2 py-1 rounded-primary"
                    >
                      <PlusIcon className="w-5 h-5 text-primary" />
                      <span> Add Existing</span>
                    </div>
                  </div>
                  {addAssetsModal && (
                    <div className="z-10 top-0  right-0 shadow-xl absolute w-[300px] shadow-gray-300 border bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                      <div className="py-2 px-4">
                        <div className="flex flex-col">
                          <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                            <input
                              type="text"
                              onChange={handleAssetSearch}
                              placeholder="Type here to search for artists"
                              className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                            />
                          </div>
                          <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                          <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                            {assets?.map((asset, index) => (
                              <div
                                onClick={() => handleSelectedAsset(asset.id)}
                                key={index}
                                className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                                  index === 0 && "bg-gray-200"
                                }`}
                              >
                                <span className="capitalize">
                                  {asset.title}
                                </span>
                              </div>
                            ))}
                            {assets.length === 0 ? (
                              <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                                Could not find anything
                              </div>
                            ) : null}
                          </ul>
                          <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                          <div
                            onClick={handleAssetModal}
                            className="flex flex-row-reverse pt-2"
                          >
                            <button className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {assetInProduct && (
            <div className="relative shadow-md">
              <div className="w-full text-sm text-left">
                {assetInProduct.map((assetItem, index) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center  py-3 p-6 text-primary font-light border-b border-[#E2E2E2] bg-white text-xs"
                    >
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                        <Link to={`/catalog/assets/${assetItem.asset.id}`} className="inline-block max-w-full overflow-hidden whitespace-nowrap font-light">
                          {assetItem.asset.title}
                        </Link>
                      </div>
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                        <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                          {assetItem.asset.trackVersion
                            ? assetItem.asset.trackVersion
                            : "-"}
                        </div>
                      </div>
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                        <div className="inline-block max-w-full overflow-hidden whitespace-nowrap font-light">
                          {assetItem.asset.displayArtists
                            ? assetItem.asset.displayArtists
                            : "-"}
                        </div>
                      </div>
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                        <div className="inline-block max-w-full overflow-hidden whitespace-nowrap font-light">
                          {assetItem.asset.isrc ? assetItem.asset.isrc : "-"}
                        </div>
                      </div>
                      <div
                        onClick={() => assetToBeDeleted(assetItem.id)}
                        className="flex-[1_1_200px] mr-[10px] text-xs text-left"
                      >
                        <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                          <div className="px-2 py-1 cursor-pointer hover:bg-gray-100 rounded-full">
                            <TrashIcon className="w-6 h-6" />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        {/* ARTIST */}
        <div className="bg-white border border-[#e2e2e2] py-4 px-6 mt-12">
          <div className="flex  justify-between items-center gap-2  px-6 text-xs text-[#555555B3] uppercase cursor-pointer relative">
            <div>Artists</div>
            <div
              className="flex items-center gap-2 hover:bg-gray-100 py-1 px-2 rounded-primary"
              onClick={() => setAddArtistDropdownModal(true)}
            >
              <PlusIcon className="w-6 h-6 text-primary" />
              <span>Add artist</span>
            </div>
            {addArtistDropdownModal && (
              <div className="z-10 top-0  right-0 shadow-xl absolute w-[300px] shadow-gray-300 border bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                <div className="py-2 px-4">
                  <div className="flex flex-col">
                    <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                      <input
                        type="text"
                        onChange={handleArtistSearch}
                        placeholder="Type here to search for artists"
                        className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                      />
                    </div>
                    <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                    <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                      {filteredArtist.map((artist, index) => (
                        <div
                          onClick={() => handleSelectedArtist(artist.id)}
                          key={index}
                          className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                            index === 0 && "bg-gray-200"
                          }`}
                        >
                          <span className="capitalize">{artist.name}</span>
                        </div>
                      ))}
                      {filteredArtist.length === 0 ? (
                        <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                          Could not find anything
                        </div>
                      ) : null}
                    </ul>
                    <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                    <div className="flex flex-row-reverse pt-2">
                      <button
                        onClick={handleAddArtistDropdownModal}
                        className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {artistToProduct && (
            <div className="py-4 px-6">
              <div
                className={
                  artistProducts.length > 0
                    ? "grid grid-cols-4 gap-2 items-center"
                    : "flex items-center justify-center"
                }
              >
                {artistProducts.length > 0 ? (
                    artistProducts.map((artistItem, index) => {
                    return (
                      <div key={index} className="border rounded-primary">
                        <div className="py-2 px-4">
                          <div className="flex items-center justify-between">
                            <div className="block">
                              <h1 className="text-primary text-xs font-bold">
                                {artistItem.artist.name}
                              </h1>
                              <div className="flex items-center gap-1 py-1">
                                <label className="relative inline-flex items-center cursor-pointer">
                                  <input
                                    type="checkbox"
                                    value=""
                                    className="sr-only peer"
                                    checked={
                                      artistItem.artistRole === "Primary"
                                    }
                                    onChange={() =>
                                      handleRoleChange(
                                        artistItem.id,
                                        artistItem.artistRole
                                      )
                                    }
                                  />
                                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none  peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"></div>
                                  <span className="ml-3 text-xs text-gray-500">
                                    {artistItem.artistRole}
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div
                              onClick={() => artistToBeDeleted(artistItem.id)}
                              className="cursor-pointer hover:bg-gray-200 p-2 rounded-full"
                            >
                              <TrashIcon className="w-6 h-6 text-primary" />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-gray-200">No artist data </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* METADATA */}
        <div className="bg-white border border-[#e2e2e2] py-4 px-6 mt-12">
          <div className="flex items-center gap-2  px-6 text-xs text-[#555555B3] uppercase">
            Metadata
          </div>
          <div className="flex items-center px-6 text-xs font-semibold text-black mt-4">
            Main
          </div>
          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">
            <div>
              <label
                htmlFor="productTitle"
                className="text-xs text-[#5F5F60B3]"
              >
                Title
              </label>
              <input
                type="text"
                name="title"
                id="productTitle"
                onChange={onChange}
                defaultValue={productTitle}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div>
              <label
                htmlFor="productArtists"
                className="text-xs text-[#5F5F60B3]"
              >
                Display Artists
              </label>
              <input
                type="text"
                name="productArtists"
                id="productArtists"
                readOnly
                defaultValue={productArtists
                  .map((artistItem) => artistItem?.artist?.name)
                  .join(", ")}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div>
              <label
                htmlFor="productReleaseVersion"
                className="text-xs text-[#5F5F60B3]"
              >
                Release Version
              </label>
              <input
                type="text"
                name="releaseVersion"
                id="productReleaseVersion"
                onChange={onChange}
                defaultValue={productReleaseVersion}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div className="relative">
              <label
                htmlFor="productLabel"
                className="text-xs text-[#5F5F60B3]"
              >
                Label
              </label>
              <input
                type="text"
                name="labelId"
                id="productLabel"
                defaultValue={productLabel}
                onClick={() => setLabelModal(!labelModal)}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
              {labelModal && (
                <div className="z-10 top-0  right-0 shadow-xl absolute w-[300px] shadow-gray-300 border bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                  <div className="py-2 px-4">
                    <div className="flex flex-col">
                      <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                        <input
                          type="text"
                          onChange={handleLabelSearch}
                          placeholder="Type here to search for labels"
                          className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                        />
                      </div>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                        {filteredLabel.map((label, index) => (
                          <div
                            onClick={() => handleLabelSelection(label.id, label.name)}
                            key={index}
                            className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                              index === 0 && "bg-gray-200"
                            }`}
                          >
                            <span className="capitalize">{label.name}</span>
                          </div>
                        ))}
                        {filteredLabel.length === 0 ? (
                          <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                            Could not find anything
                          </div>
                        ) : null}
                      </ul>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <div className="flex flex-row-reverse pt-2">
                        <button
                          onClick={() => setLabelModal(!labelModal)}
                          className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center px-6 text-xs font-semibold text-black mt-4">
            Format Details
          </div>
          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">
            <div className="relative">
              <label
                htmlFor="productFormat"
                className="text-xs text-[#5F5F60B3]"
              >
                Format
              </label>
              <input
                type="text"
                name="format"
                id="productFormat"
                onClick={() => setFormatModal(!formatModal)}
                defaultValue={format}
                className="block uppercase w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
              {formatModal && (
                <div className="z-10 top-0  left-0 shadow-xl absolute w-[300px] shadow-gray-300 bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                  <div className="py-2 px-4">
                    <div className="flex flex-col">
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                        {formatOptions.map((opt, index) => (
                          <div
                            onClick={() => handleFormatSelection(opt)}
                            key={index}
                            className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                              index === 0 && "bg-gray-200"
                            }`}
                          >
                            <span className="capitalize">{opt}</span>
                          </div>
                        ))}
                      </ul>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <div className="flex flex-row-reverse pt-2">
                        <button
                          onClick={() => setFormatModal(!formatModal)}
                          className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="relative">
              <label
                htmlFor="productTotalVolumes"
                className="text-xs text-[#5F5F60B3]"
              >
                Total Volumes
              </label>
              <input
                type="text"
                name="totalVolumes"
                id="productTotalVolumes"
                placeholder="Enter a number from 1-20"
                onChange={onChange}
                defaultValue={productTotalVolumes}
                className=" block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div className="mt-4">
              <div className="flex items-center gap-1 py-1">
                <label
                  htmlFor="productIsCompilation"
                  onClick={() => handleCompilationChange(!isCompilation)}
                  className="relative inline-flex items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    className="hidden peer"
                    checked={isCompilation === true}
                    name="isCompilation"
                    id="isCompilation"
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none  peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"></div>
                  <span className="ml-3 text-xs text-gray-500">
                    Compilation
                  </span>
                </label>
              </div>
            </div>
            <div className="relative">
              <label
                htmlFor="productExplicitness"
                className="text-xs text-[#5F5F60B3]"
              >
                Explicitness
              </label>
              <input
                type="text"
                name="explicitness"
                id="productExplicitness"
                defaultValue={explicitness}
                onClick={() => setExplicitnessModal(!explicitnessModal)}
                className=" block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
              {explicitnessModal && (
                <div className="z-10 top-0  left-0 shadow-xl absolute w-[300px] shadow-gray-300 bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                  <div className="py-2 px-4">
                    <div className="flex flex-col">
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                        {explicitnessOptions.map((opt, index) => (
                          <div
                            key={index}
                            onClick={() => handleExplicetSelection(opt)}
                            className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                              index === 0 && "bg-gray-200"
                            }`}
                          >
                            <span className="capitalize">{opt}</span>
                          </div>
                        ))}
                      </ul>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <div className="flex flex-row-reverse pt-2">
                        <button
                          onClick={() =>
                            setExplicitnessModal(!explicitnessModal)
                          }
                          className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center px-6 text-xs font-semibold text-black mt-4">
            Identifiers
          </div>
          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">
            <div className="relative">
              <label htmlFor="productUpc" className="text-xs text-[#5F5F60B3]">
                Barcode
              </label>
              <input
                  type="text"
                  name="upc"
                  id="productUpc"
                  onChange={onChange}
                  defaultValue={productUpc}
                  className="block uppercase w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            {!productUpc && isBarcodeGeneratable && (
                <div className="relative">
                  <div
                      onClick={() => setBarcodeNumberModal(!barcodeNumberModal)}
                      className="cursor-pointer"
                  >
                <span className="border-b border-primary text-xs text-primary normal-case">
                 Do you need a barcode number?
                </span>
                  </div>
                </div>)}
            <div className="relative">
              <label
                  htmlFor="productCatalogNumber"
                  className="text-xs text-[#5F5F60B3]"
              >
                Catalog Number
              </label>
              <input
                  type="text"
                  name="catalogNumber"
                  id="productCatalogNumber"
                  onChange={onChange}
                  defaultValue={productCatalogNumber}
                  className="block uppercase w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
          </div>
          <div className="flex items-center px-6 text-xs font-semibold text-black mt-4">
            Default Dates
          </div>
          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">
            <div>
              <label
                  htmlFor="productConsumerReleaseDate"
                  className="text-xs text-[#5F5F60B3]"
              >
                Consumer Release Date
              </label>
              <div className="flex items-center gap-1">
                <DatePicker
                    selected={
                      consumerReleaseDate ? new Date(consumerReleaseDate) : null
                    }
                    onChange={handleConsumerReleaseDateSelection}
                  dateFormat="yyyy/MM/dd"
                  className="block cursor-pointer w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
                />
                <CalendarDaysIcon className="w-6 h-6 text-primary" />
              </div>
            </div>
            <div>
              <label
                htmlFor="preorderDate"
                className="text-xs text-[#5F5F60B3]"
              >
                Preorder Date
              </label>
              <div className="flex items-center gap-1">
                <DatePicker
                  selected={preorderDate && new Date(preorderDate)}
                  onChange={handlePreOrderDateSelection}
                  dateFormat="yyyy/MM/dd"
                  className="block cursor-pointer w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
                />
                <CalendarDaysIcon className="w-6 h-6 text-primary" />
              </div>
            </div>
          </div>
          <div className="flex items-center px-6 text-xs font-semibold text-black mt-4">
            Original Release
          </div>
          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">
            <div>
              <label
                htmlFor="productOriginalReleaseDate"
                className="text-xs text-[#5F5F60B3]"
              >
                Original Release Date
              </label>
              <div className="flex items-center gap-1">
                <DatePicker
                  selected={
                    originalReleaseDate ? new Date(originalReleaseDate) : null
                  }
                  onChange={handleOriginalReleaseDateSelection}
                  dateFormat="yyyy/MM/dd"
                  className="block cursor-pointer w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
                />
                <CalendarDaysIcon className="w-6 h-6 text-primary" />
              </div>
            </div>
            <div className="relative">
              <label
                htmlFor="productRecordingYear"
                className="text-xs text-[#5F5F60B3]"
              >
                Recording Year
              </label>
              <input
                type="text"
                name="recordingYear"
                id="productRecordingYear"
                onChange={onChange}
                defaultValue={productRecordingYear}
                className="block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div className="relative">
              <label
                htmlFor="productRecordingLocation"
                className="text-xs text-[#5F5F60B3]"
              >
                Recording Location
              </label>
              <input
                type="text"
                name="recordingLocation"
                id="productRecordingLocation"
                onChange={onChange}
                defaultValue={productRecordingLocation}
                className="block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
          </div>
          <div className="flex items-center px-6 text-xs font-semibold text-black mt-4">
            Copyrights
          </div>
          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">
            <div className="relative">
              <label
                htmlFor="productp_CopyrightYear"
                className="text-xs text-[#5F5F60B3]"
              >
                © copyright Year
              </label>
              <input
                type="text"
                name="p_CopyrightYear"
                id="productp_CopyrightYear"
                onChange={onChange}
                defaultValue={productp_CopyrightYear}
                className="block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div className="relative">
              <label
                htmlFor="productp_CopyrightText"
                className="text-xs text-[#5F5F60B3]"
              >
                © copyright Text
              </label>
              <input
                type="text"
                name="p_CopyrightText"
                id="productp_CopyrightText"
                onChange={onChange}
                defaultValue={productp_CopyrightText}
                className="block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div className="relative">
              <label
                htmlFor="productc_CopyrightYear"
                className="text-xs text-[#5F5F60B3]"
              >
                © copyright Year
              </label>
              <input
                type="text"
                name="c_CopyrightYear"
                id="productc_CopyrightYear"
                onChange={onChange}
                defaultValue={productc_CopyrightYear}
                className="block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div className="relative">
              <label
                htmlFor="productp_CopyrightText"
                className="text-xs text-[#5F5F60B3]"
              >
                © copyright Text
              </label>
              <input
                type="text"
                name="c_CopyrightText"
                id="productp_CopyrightText"
                onChange={onChange}
                defaultValue={productc_CopyrightText}
                className="block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
          </div>
          <div className="flex items-center px-6 text-xs font-semibold text-black mt-4">
            Genre
          </div>
          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">
            <div className="relative">
              <label
                htmlFor="productGenre"
                className="text-xs text-[#5F5F60B3]"
              >
                Genre
              </label>
              <input
                type="text"
                name="genre"
                id="genre"
                defaultValue={
                  selectedGenreName ? selectedGenreName : productGenre
                }
                onClick={() => setGenreModal(!genreModal)}
                className="block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
              {genreModal && (
                <div className="z-10 top-0  left-0 shadow-xl absolute w-[300px] shadow-gray-300 bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                  <div className="py-2 px-4">
                    <div className="flex flex-col">
                      <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                        <input
                          type="text"
                          onChange={handleGenreSearch}
                          placeholder="Type here to search for genre"
                          className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                        />
                      </div>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                        {filteredGenre.map((opt, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedGenre(opt.id, opt.name)
                            }
                            className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                              index === 0 && "bg-gray-200"
                            }`}
                          >
                            <span className="capitalize">{opt.name}</span>
                          </div>
                        ))}
                      </ul>
                      {filteredGenre.length === 0 ? (
                        <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                          Could not find anything
                        </div>
                      ) : null}
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <div className="flex flex-row-reverse pt-2">
                        <button
                          onClick={() => setGenreModal(!genreModal)}
                          className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="relative">
              <label
                htmlFor="productSubgenre"
                className="text-xs text-[#5F5F60B3]"
              >
                Subgenre
              </label>
              <input
                type="text"
                name="subgenre"
                id="subgenre"
                defaultValue={
                  selectedSubgenreName ? selectedSubgenreName : productSubgenre
                }
                onClick={() => setSubgenreModal(!subgenreModal)}
                className="block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
              {subgenreModal && (
                <div className="z-10 top-0  left-0 shadow-xl absolute w-[300px] shadow-gray-300 bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                  <div className="py-2 px-4">
                    <div className="flex flex-col">
                      <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                        <input
                          type="text"
                          onChange={addNewSubgenre}
                          placeholder="Type here to search for subgenre"
                          className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                        />
                      </div>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                        {filteredSubgenre.map((opt, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedSubgenre(opt.id, opt.name)
                            }
                            className={`rounded-primary px-4 w-full flex justify-between relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                              index === 0 && "bg-gray-200"
                            }`}
                          >
                            <span className="capitalize">{opt.name}</span>
                            <TrashIcon
                              onClick={() => deleteSubgenre(opt.id)}
                              className="w-6 h-6 text-gray-500 hover:bg-gray-100 rounded-full"
                            />
                          </div>
                        ))}
                      </ul>
                      {filteredSubgenre.length === 0 ? (
                        <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                          Could not find anything
                        </div>
                      ) : null}
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <div className="flex flex-row-reverse pt-2">
                        <button
                          onClick={addNewSubgenreRequest}
                          className={
                            newSubgenre
                              ? "py-1.5 px-2 text-primary h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                              : "py-1.5 px-2 text-gray-200"
                          }
                        >
                          Add New
                        </button>
                        <button
                          onClick={() => setSubgenreModal(!subgenreModal)}
                          className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="relative">
              <label
                htmlFor="productAlternateGenre"
                className="text-xs text-[#5F5F60B3]"
              >
                Alternate Genre
              </label>
              <input
                type="text"
                name="productAlternateGenre"
                id="productAlternateGenre"
                defaultValue={
                  selectedAlternateGenreName
                    ? selectedAlternateGenreName
                    : productAlternateGenre
                }
                onClick={() => setAlternateGenreModal(!alternateGenreModal)}
                className="block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
              {alternateGenreModal && (
                <div className="z-10 top-0  left-0 shadow-xl absolute w-[300px] shadow-gray-300 bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                  <div className="py-2 px-4">
                    <div className="flex flex-col">
                      <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                        <input
                          type="text"
                          onChange={handleAlternateGenreSearch}
                          placeholder="Type here to search for alternate genre"
                          className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                        />
                      </div>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                        {filteredAlternateGenre.map((opt, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedAlternateGenre(opt.id, opt.name)
                            }
                            className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                              index === 0 && "bg-gray-200"
                            }`}
                          >
                            <span className="capitalize">{opt.name}</span>
                          </div>
                        ))}
                      </ul>
                      {filteredAlternateGenre.length === 0 ? (
                        <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                          Could not find anything
                        </div>
                      ) : null}
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <div className="flex flex-row-reverse pt-2">
                        <button
                          onClick={() =>
                            setAlternateGenreModal(!alternateGenreModal)
                          }
                          className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="relative">
              <label
                htmlFor="productAlternateSubgenre"
                className="text-xs text-[#5F5F60B3]"
              >
                Alternate Subgenre
              </label>
              <input
                type="text"
                name="productAlternateSubgenre"
                id="productAlternateSubgenre"
                defaultValue={
                  selectedAlternateSubgenreName
                    ? selectedAlternateSubgenreName
                    : productAlternateSubgenre
                }
                onClick={() =>
                  setAlternateSubgenreModal(!alternateSubgenreModal)
                }
                className="block  w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
              {alternateSubgenreModal && (
                <div className="z-10 top-0  left-0 shadow-xl absolute w-[300px] shadow-gray-300 bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                  <div className="py-2 px-4">
                    <div className="flex flex-col">
                      <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                        <input
                          type="text"
                          onChange={addNewAlternateSubgenre}
                          placeholder="Type here to search for alternate subgenre"
                          className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                        />
                      </div>
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                        {filteredAlternateSubgenre.map((opt, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedAlternateSubgenre(opt.id, opt.name)
                            }
                            className={`rounded-primary px-4 w-full flex justify-between relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                              index === 0 && "bg-gray-200"
                            }`}
                          >
                            <span className="capitalize">{opt.name}</span>
                            <TrashIcon
                              onClick={() => deleteAlternateSubgenre(opt.id)}
                              className="w-6 h-6 text-gray-500 hover:bg-gray-100 rounded-full"
                            />
                          </div>
                        ))}
                      </ul>
                      {filteredAlternateSubgenre.length === 0 ? (
                        <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                          Could not find anything
                        </div>
                      ) : null}
                      <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                      <div className="flex flex-row-reverse pt-2">
                        <button
                          onClick={addNewSubgenreRequest}
                          className={
                            newSubgenre
                              ? "py-1.5 px-2 text-primary h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                              : "py-1.5 px-2 text-gray-200"
                          }
                        >
                          Add New
                        </button>
                        <button
                          onClick={() => setAlternateSubgenreModal(!alternateSubgenreModal)}
                          className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center px-6 text-xs font-semibold text-black mt-4">
            Promotion
          </div>
          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">
            <div className="relative">
              <label
                htmlFor="productAlbumNotes"
                className="text-xs text-[#5F5F60B3]"
              >
                Album Notes
              </label>
              <input
                type="text"
                name="productAlbumNotes"
                id="productAlbumNotes"
                readOnly
                value={
                  productAlbumNotes ? "Added album notes" : "No album notes"
                }
                onClick={() => setAlbumNotesModal(!albumNotesModal)}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            {albumNotesModal && (
              <div>
                <Transition.Root show={albumNotesModal} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => setAlbumNotesModal(!albumNotesModal)}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                            <div>
                              <div className="text-lg font-normal px-4 pt-5">
                                <h1>Album Notes</h1>
                              </div>
                              <div className="mt-3 sm:mt-5 flex items-center gap-3 px-4 pb-4">
                                <div className="relative w-full">
                                  <textarea
                                    name="albumNotes"
                                    id="albumNotes"
                                    onChange={onChange}
                                    defaultValue={productAlbumNotes}
                                    className="block w-full border border-gray-300 p-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary focus:border-primary"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                              <div></div>
                              <div className="flex items-center gap-4">
                                <button
                                  type="button"
                                  className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                  onClick={() =>
                                    setAlbumNotesModal(!albumNotesModal)
                                  }
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>
              </div>
            )}
            <div className="relative">
              <label
                htmlFor="productLabelCopyInfo"
                className="text-xs text-[#5F5F60B3]"
              >
                Label Copy Info
              </label>
              <input
                type="text"
                name="productLabelCopyInfo"
                id="productLabelCopyInfo"
                readOnly
                value={
                  productLabelCopyInfo
                    ? "Added label copy info"
                    : "No label copy info"
                }
                onClick={() => setLabelCopyInfoModal(!labelCopyInfoModal)}
                className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            {labelCopyInfoModal && (
              <div>
                <Transition.Root show={labelCopyInfoModal} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => setLabelCopyInfoModal(!labelCopyInfoModal)}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                            <div>
                              <div className="text-lg font-normal px-4 pt-5">
                                <h1>Label Copy Info</h1>
                              </div>
                              <div className="mt-3 sm:mt-5 flex items-center gap-3 px-4 pb-4">
                                <div className="relative w-full">
                                  <textarea
                                    name="labelCopyInfo"
                                    id="labelCopyInfo"
                                    onChange={onChange}
                                    defaultValue={productLabelCopyInfo}
                                    className="block w-full border border-gray-300 p-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary focus:border-primary"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                              <div></div>
                              <div className="flex items-center gap-4">
                                <button
                                  type="button"
                                  className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                  onClick={() =>
                                    setLabelCopyInfoModal(!labelCopyInfoModal)
                                  }
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>
              </div>
            )}
          </div>

          <div className="flex items-center px-6 text-xs font-semibold text-black mt-4">
            Other
          </div>

          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">

            <div className="relative">
              <label
                  htmlFor="productCatalogTier"
                  className="text-xs text-[#5F5F60B3]"
              >
                Catalog Tier
              </label>
              <input
                  type="text"
                  name="catalogTier"
                  id="productCatalogTier"
                  defaultValue={
                    selectedProductCatalogTier
                        ? selectedProductCatalogTier
                        : productCatalogTier
                  }
                  onClick={() => setCatalogModal(!catalogModal)}
                  className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
              {catalogModal && (
                  <div
                      className="z-10 top-18  left-0 shadow-xl absolute w-[300px] shadow-gray-300 bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                    <div className="py-2 px-4">
                      <div className="flex flex-col">
                        <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                          {['Budget', 'Back', 'Mid', 'Front', 'Premium'].map((opt, index) => (
                              <div
                                  key={index}
                                  onClick={() =>
                                      handleSelectedProductCatalogTier(opt)
                                  }
                                  className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                                      index === 0 && "bg-gray-200"
                                  }`}
                              >
                                <span className="capitalize">{opt}</span>
                              </div>
                          ))}
                        </ul>
                        <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]"/>
                        <div className="flex flex-row-reverse pt-2">
                          <button
                              onClick={() =>
                                  setCatalogModal(!catalogModal)
                              }
                              className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>

        {/* BARCODE NUMBERs */}
        {barcodeNumberModal && (
            <div>
              <Transition.Root show={barcodeNumberModal} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => setBarcodeNumberModal(!barcodeNumberModal)}
                >
                  <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel
                            className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                          <div>
                            <div className="px-4 py-10 flex flex-col items-center gap-4">
                              <h1 className="text-lg font-semibold">
                                Generate Barcode Number
                              </h1>
                              <p className="text-md">
                                To ensure consistency and avoid potential conflicts, it is essential to use your
                                existing barcode number.
                              </p>
                              <div className="relative">
                                <div className="flex items-center gap-1 py-1">
                                  <label
                                      htmlFor="barcodeAssign"
                                      className="relative inline-flex items-center cursor-pointer"
                                  >
                                    <input
                                        onChange={handleOnBarcodeAssignAction}
                                        type="checkbox"
                                        className="hidden peer"
                                        checked={isBarcodeAssigned === true}
                                        name="barcodeAssign"
                                        id="barcodeAssign"
                                    />
                                    <div
                                        className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"/>
                                    <span className="ml-3 text-xs text-gray-500">
                                      Yes, generate barcode number
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                              <div></div>
                              <div className="flex items-center gap-4">
                                <button
                                    type="button"
                                    className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                    onClick={() => setBarcodeNumberModal(!barcodeNumberModal)}

                                >
                                  Cancel
                                </button>
                                <button
                                    type="button"
                                    className="ml-6 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                    onClick={handleOnBarcodeAssign}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            </div>
        )}

        {/* TRANSLATION */}
        <div className="bg-white border border-[#e2e2e2] py-4 px-6 mt-12">

          <div className="grid grid-cols-4 gap-x-36 gap-y-4 px-6 py-4">
            <div className="relative">
              <label
                  onClick={() => setMetadataLanguageModal(true)}
                  className="text-xs text-[#5F5F60B3] "
              >
                Metadata Language
              </label>
              <div
                  onClick={() => setMetadataLanguageModal(true)}
                  className="block w-full border-b cursor-pointer border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2 relative"
              >
                {selectedLanguage ? selectedLanguage : productLanguage}
              </div>
              {metadataLanguageModal && (
                  <div
                      className="z-10 absolute w-[300px] shadow-primary bg-white text-[#212121] top-0 left-0 rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                    <div className="py-2 px-4">
                      <div className="flex flex-col">
                        <div
                            className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                          <input
                              type="text"
                              onChange={handleTranslationSearch}
                              placeholder="Type here to search for language"
                              className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                          />
                        </div>
                        <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                        <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                          {filteredLanguage.map((language, index) => (
                              <div
                                  onClick={() =>
                                      handleSelectedMetadataLanguage(language)
                                  }
                                  key={index}
                                  className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                                      index === 0 && "bg-gray-200"
                                  }`}
                              >
                                <span className="capitalize">{language}</span>
                              </div>
                          ))}
                          {filteredLanguage.length === 0 ? (
                              <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                                Could not find anything
                              </div>
                          ) : null}
                        </ul>
                        <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                        <div
                            onClick={() => setMetadataLanguageModal(false)}
                            className="flex flex-row-reverse pt-2"
                        >
                          <button className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
              )}
            </div>
          </div>

          <div className="flex items-center gap-2  px-6 text-xs text-[#555555B3] uppercase mb-4">
            Translations
          </div>
          <div className="flex items-center h-[50px] py-3 p-6 text-[#212121] font-light border-b border-[#E2E2E2] bg-white text-xs">
            <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
              <span className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                Language
              </span>
            </div>
            <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
              <span className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                Product Title
              </span>
            </div>
            <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
              <span className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                Product Release Version
              </span>
            </div>
            <div className="flex-[0_1_200px] mr-[10px] text-xs text-right relative">
              <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                <div
                  onClick={() => setAddTranslation(true)}
                  className="flex items-center gap-2  hover:bg-gray-100 p-2 rounded-primary cursor-pointer"
                >
                  <PlusIcon className="w-5 h-5 text-primary" />
                  <span className="text-right">Add Translation</span>
                  <ChevronDownIcon className="w-5 h-5- text-primary" />
                </div>
                {addTranslation && (
                  <div className="z-10 absolute w-[300px] shadow-primary bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                    <div className="py-2 px-4">
                      <div className="flex flex-col">
                        <div className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                          <input
                            type="text"
                            onChange={handleTranslationSearch}
                            placeholder="Type here to search for language"
                            className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                          />
                        </div>
                        <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                        <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                          {filteredLanguage.map((language, index) => (
                            <div
                              onClick={() => handleSelectedLanguage(language)}
                              key={index}
                              className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                                index === 0 && "bg-gray-200"
                              }`}
                            >
                              <span className="capitalize">{language}</span>
                            </div>
                          ))}
                          {filteredLanguage.length === 0 ? (
                            <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                              Could not find anything
                            </div>
                          ) : null}
                        </ul>
                        <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]" />
                        <div
                          onClick={closeTranslationModal}
                          className="flex flex-row-reverse pt-2"
                        >
                          <button className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {translations.length === 0 && (
            <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center">
              No items available
            </div>
          )}

          {Array.isArray(productTranslations) &&
            productTranslations.map((translation, index) => {
              return (
                <div
                  key={translation.id}
                  className={
                    index % 2 === 0
                      ? "flex items-center h-[50px] py-4 p-6 text-[#212121] font-light bg-white text-xs"
                      : "bg-[#dc64780f] flex items-center h-[50px] py-4 p-6 text-[#212121] font-light text-xs"
                  }
                >
                  <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                    <span className="inline-block max-w-full overflow-hidden whitespace-nowrap">
                      {translation.language}
                    </span>
                  </div>
                  <div className="flex-[1_1_200px] text-xs text-left">
                    <span className="inline-block max-w-full overflow-hidden whitespace-nowrap">
                      {translation.productTitle
                        ? translation.productTitle
                        : "-"}
                    </span>
                  </div>
                  <div className="flex-[1_1_200px] text-xs text-left">
                    <span className="inline-block max-w-full overflow-hidden whitespace-nowrap">
                      {translation.releaseVersion
                        ? translation.releaseVersion
                        : "-"}
                    </span>
                  </div>
                  <div className="flex-[0_1_200px] mr-[20px] text-xs text-right relative">
                    <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                      <div className="flex items-center gap-6">
                        <div
                          onClick={() =>
                            selectProductNameForEditing(
                              translation.productTitle,
                              translation.id
                            )
                          }
                          className="p-2 hover:bg-gray-100 rounded-full cursor-pointer"
                        >
                          <PencilIcon className="w-6 h-6 text-gray-500" />
                        </div>
                        <div
                          onClick={() =>
                            handleDeleteModalTranslation(translation.id)
                          }
                          className="p-2 hover:bg-gray-100 rounded-full cursor-pointer"
                        >
                          <TrashIcon className="w-6 h-6 text-gray-500" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          {deleteModalTranslation && (
            <>
              <Transition.Root show={deleteModalTranslation} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-10"
                  onClose={closeModalDeleteTranslation}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                          <div>
                            <div className="text-lg font-normal px-4 pt-5">
                              <h1>Remove translation</h1>
                              <p className="text-sm font-light py-4 text-[#757575] flex">
                                Are you sure you want to remove this translation
                                from the Product?
                              </p>
                            </div>
                          </div>
                          <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                            <div></div>
                            <div className="flex items-center gap-4">
                              <button
                                type="button"
                                className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase outline-none hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                onClick={handleDeleteModal}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-white bg-primary uppercase hover:bg-primary/80 sm:col-start-1 sm:mt-0"
                                onClick={deleteAddedTranslationData}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            </>
          )}
          {openTranslationModal && (
            <>
              <Transition.Root show={openTranslationModal} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-10"
                  onClose={closeModalT}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                          <div>
                            <div className="text-lg font-normal px-4 pt-5">
                              <h1>Add translations</h1>
                              <p className="text-sm font-light mt-2 text-[#757575] flex">
                                Insert translations by clicking the{" "}
                                <PencilIcon className="w-6 h-6 px-1" /> button
                                and saving it.
                              </p>
                            </div>
                            <div className="relative w-ful">
                              <div className="overflow-visible">
                                <div className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-light border-b border-[#e2e2e2] uppercase bg-white pb-4">
                                  <div className="flex-[1_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Field
                                    </span>
                                  </div>
                                  <div className="flex-[1_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Original
                                    </span>
                                  </div>
                                  <div className="flex-[1.5_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Translation
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="relative w-ful py-4">
                              <div className="overflow-visible">
                                <div className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-normal bg-white">
                                  <div className="flex-[1_1_200px] text-[12px] text-left px-4 ">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Title
                                    </span>
                                  </div>
                                  <div className="flex-[1_1_200px] text-[12px] text-left px-4 ">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      {productTitle}
                                    </span>
                                  </div>
                                  <div className="flex items-center justify-between flex-[1.5_1_200px] text-[12px] text-left px-4 ">
                                    {editTranslation ? (
                                      <div className="flex items-center w-5/6 gap-4">
                                        <input
                                          type="text"
                                          defaultValue={editedProductTitle}
                                          onChange={handleChangeTranslation}
                                          className="w-full border-b-2 outline-none focus:border-b-primary hover:border-b-primary"
                                        />
                                        <button
                                          onClick={createTranslationData}
                                          className="uppercase font-semibold text-primary hover:bg-primary/10 py-1 px-4 rounded-md"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    ) : (
                                      <span className="flex-1 w-full overflow-hidden whitespace-nowrap text-ellipsis">
                                        {editedProductTitle}
                                      </span>
                                    )}
                                    <div className="flex items-center justify-center cursor-pointer hover:bg-gray-100 py-1 px-4 rounded-primary">
                                      <PencilSquareIcon
                                        onClick={openEditTranslationModal}
                                        className="text-black w-6 h-6"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                            <div></div>
                            <div className="flex items-center gap-4">
                              <button
                                type="button"
                                className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0 focus:outline-none"
                                onClick={closeModalT}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            </>
          )}
          {editAddedTranslation && (
            <>
              <Transition.Root show={editAddedTranslation} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-10"
                  onClose={selectProductNameForEditing}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                          <div>
                            <div className="text-lg font-normal px-4 pt-5">
                              <h1>Add translations</h1>
                              <p className="text-sm font-light mt-2 text-[#757575] flex">
                                Insert translations by clicking the{" "}
                                <PencilIcon className="w-6 h-6 px-1" /> button
                                and saving it.
                              </p>
                            </div>
                            <div className="relative w-ful">
                              <div className="overflow-visible">
                                <div className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-light border-b border-[#e2e2e2] uppercase bg-white pb-4">
                                  <div className="flex-[1_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Field
                                    </span>
                                  </div>
                                  <div className="flex-[1_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Original
                                    </span>
                                  </div>
                                  <div className="flex-[1.5_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Translation
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="relative w-ful py-4">
                              <div className="overflow-visible">
                                <div className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-normal bg-white">
                                  <div className="flex-[1_1_200px] text-[12px] text-left px-4 ">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Title
                                    </span>
                                  </div>
                                  <div className="flex-[1_1_200px] text-[12px] text-left px-4 ">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      {productTitle}
                                    </span>
                                  </div>
                                  <div className="flex items-center justify-between flex-[1.5_1_200px] text-[12px] text-left px-4 ">
                                    {editTranslation ? (
                                      <div className="flex items-center w-5/6 gap-4">
                                        <input
                                          type="text"
                                          defaultValue={editingTranslationName}
                                          onChange={handleChangeTranslation}
                                          className="w-full border-b-2 outline-none focus:border-b-primary hover:border-b-primary"
                                        />
                                        <button
                                          onClick={editedAddedTranslationData}
                                          className="uppercase font-semibold text-primary hover:bg-primary/10 py-1 px-4 rounded-md"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    ) : (
                                      <span className="flex-1 w-full overflow-hidden whitespace-nowrap text-ellipsis">
                                        {editingTranslationName}
                                      </span>
                                    )}
                                    <div className="flex items-center justify-center cursor-pointer hover:bg-gray-100 py-1 px-4 rounded-primary">
                                      <PencilSquareIcon
                                        onClick={openEditTranslationModal}
                                        className="text-black w-6 h-6"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                            <div></div>
                            <div className="flex items-center gap-4">
                              <button
                                type="button"
                                className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase outline-none hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                onClick={selectProductNameForEditing}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            </>
          )}
        </div>
        {/* TERRITORIES */}
        <div className="bg-white border border-[#e2e2e2] py-4 px-6 mt-12">
          <div className="flex items-center justify-between border-b  pb-4">
            <div className="flex items-center gap-4 px-6 text-xs text-[#555555B3] uppercase">
              <div>
                <span>Territories</span>
              </div>
              <div
                onClick={() => setTerritoriesModal(!territoriesModal)}
                className="cursor-pointer"
              >
                <span className="border-b border-primary text-xs text-primary normal-case">
                  Change territory product override
                </span>
              </div>
            </div>
            <div className="px-6 flex items-center gap-1 uppercase text-xs text-gray-300 ">
              <PlusIcon className="w-6 h-6" />
              Add Override
            </div>
          </div>
          <div className="flex items-center justify-center text-xs text-gray-400 py-12">
            No overrides added for this product.
          </div>
        </div>
        {territoriesModal && (
          <div>
            <Transition.Root show={territoriesModal} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={() => setTerritoriesModal(!territoriesModal)}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                        <div>
                          <div className="text-lg font-normal px-4 pt-5">
                            <h1>Change territory product override</h1>
                            <div className="pt-4">
                              <h1 className="text-xs text-primary font-semibold">
                                <span className="text-xs text-gray-500 ml-1 font-light">
                                  Product:{" "}
                                </span>
                                {productTitle}
                              </h1>
                              <h1 className="text-xs text-primary font-semibold">
                                <span className="text-xs text-gray-500 ml-1 font-light">
                                  Artists:{" "}
                                </span>
                                {productArtists.length > 0
                                  ? productArtists
                                      .map(
                                        (artistItem) => artistItem.artist.name
                                      )
                                      .join(", ")
                                  : "-"}
                              </h1>
                            </div>
                          </div>

                          <div className="bg-white">
                            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-12 lg:px-8 lg:py-4">
                              <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                                <dl>
                                  <div className="flex items-center gap-2 mb-4 border-b cursor-pointer pb-4">
                                    <input
                                      type="checkbox"
                                      id={`selectAll-regions`}
                                      checked={allRegionsSelected}
                                      onChange={handleAllRegionsCheckChange}
                                      className="outline-none"
                                    />
                                    <label
                                      htmlFor={`selectAll-regions`}
                                      className="cursor-pointer"
                                    >
                                      World
                                    </label>
                                  </div>
                                  {Object.keys(regionCountries).map(
                                    (region, i) => (
                                      <div key={i} className="py-1">
                                        <div className="flex items-center justify-between border-b pb-4 cursor-pointer">
                                          <div className="flex items-center gap-2">
                                            <input
                                              type="checkbox"
                                              id={`selectAll-${region}`}
                                              checked={
                                                allSelected[region] || false
                                              }
                                              onChange={(event) =>
                                                handleAllCheckChange(
                                                  event,
                                                  region
                                                )
                                              }
                                            />
                                            <h2
                                              onClick={() =>
                                                handleRegionClick(region)
                                              }
                                            >
                                              {region}
                                            </h2>
                                          </div>
                                          <PlusIcon className="w-6 h-6 text-primary" />
                                        </div>
                                        <div className="grid grid-cols-4 gap-8 mt-4">
                                          {openRegion === region &&
                                            regionCountries[region].map(
                                              (country) => (
                                                <div
                                                  key={country.name}
                                                  className="flex items-center gap-4 text-xs"
                                                >
                                                  <input
                                                    type="checkbox"
                                                    id={country.name}
                                                    name={country.name}
                                                    checked={
                                                      selectedCountries[
                                                        country.name
                                                      ] || false
                                                    }
                                                    onChange={(event) =>
                                                      handleCheckChange(
                                                        event,
                                                        region,
                                                          country.name
                                                      )
                                                    }
                                                  />
                                                  <label htmlFor={country.name}>
                                                    {country.name}
                                                  </label>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                          <div></div>
                          <div className="flex items-center gap-4">
                            <button
                                type="button"
                                className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                onClick={() =>
                                    setTerritoriesModal(!territoriesModal)
                                }
                            >
                              Cancel
                            </button>
                            <button
                                type="button"
                                className="ml-6 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                 onClick={addTerritories}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </div>
        )}

        <div className="flex items-center gap-2 py-6">
          <div className="flex ml-auto">
            <button
                onClick={onSaveClick}
                className="ml-6 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Save Changes
            </button>
            <button
                onClick={onSendForReviewClick}
                className="ml-6 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Send For Review
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
