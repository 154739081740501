import axios from "axios";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Dialog, Transition } from '@headlessui/react';
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export default function DeleteAssets() {
    const [deleteProduct, setDeleteProduct] = useState();
    const [modal, setModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const modalRef = useRef(null);
    const cancelButtonRef = useRef(null);
    const { credential } = useSelector((state) => state.user);
    const { id } = useParams();
    const navigate = useNavigate();

    const handleModal = () => {
        setModal(!modal);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setModal(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleDeleteProduct = async () => {
        const headers = { Authorization: `Bearer ${credential}` };
        try {
            // DELETE
            const response = await axios.delete(`https://music.videomite.tech/api/product/${id}`, { headers });
            const productDetail = response.data
            setDeleteProduct(productDetail);
            setDeleteModal(false);
            navigate('/catalog/products');
        } catch (error) {
            toast.error('Product silinirken bir hata oluştu:', error);
        }
    };
  return (
   <>
            <ToastContainer autoClose={3000} />
            <div className='bg-white'>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className='py-4 text-black'>
                        <div className='relative flex items-center justify-end'>
                            <div onClick={handleModal} className='flex items-center gap-2 hover:bg-slate-100 rounded-primary py-2 px-4 cursor-pointer'>
                                <span>Actions</span>
                                <ChevronDownIcon className='text-primary font-bold w-4 h-4' />
                            </div>
                            {modal &&
                                <div ref={modalRef} onClick={() => setDeleteModal(true)} className='uppercase absolute bg-white shadow-black/50 rounded-primary shadow-md px-4 py-4 cursor-pointer'>delete</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {deleteModal &&
                <Transition.Root show={deleteModal} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setDeleteModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div>
                                                <span className='text-lg font-light leading-[27px]'>Delete asset</span>
                                            </div>
                                            <div className='mt-4'>
                                                <span className='font-light text-sm'>Are you sure you want to remove the asset from your catalog?</span>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 sm:ml-3 sm:w-auto"
                                                onClick={handleDeleteProduct}
                                            >
                                                DELETE
                                            </button>
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center px-3 py-2 text-sm font-semibold text-gray-900  hover:bg-gray-100 rounded-md uppercase  sm:mt-0 sm:w-auto"
                                                onClick={() => setDeleteModal(false)}
                                                ref={cancelButtonRef}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            }
        </>
  )
}
